import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class SurveyService {
    private _apiUrl = environment.apiURL;
    unAttemptedQuestions: Subject<any>;
    constructor(
        public http: HttpClient
    ) {
        this.unAttemptedQuestions = new Subject();
    }
    getSurveyList(body) {
        return this.http.post(this._apiUrl + '/survey-list', body);
    }

    getSurveyDetail(body) {
        return this.http.post(this._apiUrl + '/survey-detail', body);
    }

    getFilledSurveyDetails(body) {
        return this.http.post(this._apiUrl + '/survey-fill-detail', body);
    }
    submitSurvey(body) {
        return this.http.post(this._apiUrl + '/survey-fill', body);
    }
    getSurveyResult(body) {
        return this.http.post(this._apiUrl + '/survey-result', body);
    }
    surveyReadById(body) {
        return this.http.post(this._apiUrl + '/survey-read', body);
    }
} 