<div id="wrapper">
    <div id="main" class="maincontent">
        <div id="primary" class="content-area one-column">
            <div id="content" class="site-content">
                <div class="contenttopsection bgeventsnews">
                    <h3 class="sectitle bordermarron">
                        <div class="wrap">
                            {{'event_list_heading' | translate}}
                        </div>
                    </h3>
                    <div style="position: absolute;left: 50%;top: 50%;" *ngIf="isLoading">
                        <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
                        </mat-progress-spinner>
                    </div>
                    <ul class="postlisting bordermarron">
                        <div class="wrap" *ngIf="status === 0">
                            {{'no_records_found' | translate}}
                        </div>
                        <div class="wrap" *ngIf="allEvents?.length === 0">
                            {{'no_records_found' | translate}}
                        </div>
                        <li *ngFor="let event of allEvents">
                            <div [ngStyle]="{ 'background-color': event?.is_read == 1 ? '#E5ECF3' : '#BECF59'}" class="pd-10">
                                <div class="wrap mainpost" routerLink="/events-detail/{{event.id}}">
                                    <div class="postleft">
                                        <figure class="postimg mainimg" *ngIf="event.image">
                                            <img [src]="event?.image" alt="">
                                        </figure>
                                        
                                        <div class="postdetails postinformation">
                                            <p [ngStyle]="{'margin-left': (event.image !== '') ? '0px' : '0px', 'color': event?.is_read == 0 ? '#038544' : '#000'}" class="cursor-pointer">
                                                {{event?.created_at | uppercase}}
                                            </p>
                                            <ul class="postdatecat">
                                                <li class="cursor-pointer" [ngStyle]="{ 'color': event?.is_read == 0 ? '#038544' : '#000'}">{{event?.address}}</li>
                                                <li class="cursor-pointer" [ngStyle]="{ 'color': event?.is_read == 0 ? '#038544' : '#000'}">{{event?.event_start_date}}</li>
                                                <li style="margin-bottom: 10px;" [ngStyle]="{ 'color': event?.is_read == 0 ? '#038544' : '#000'}" class="cursor-pointer">
                                                    {{event?.event_end_date | uppercase}}</li>
                                            </ul>
                                            <h4><a [ngStyle]="{ 'color': event?.is_read == 0 ? '#038544' : '#000'}">{{event?.title}}</a>
                                            </h4>
                                            <div [ngStyle]="{ 'color': event?.is_read == 0 ? '#038544' : '#000'}">{{event?.short_description}}</div>
                                        </div>
                                    </div>
                                    <div class="postright bgevents"
                                        [ngStyle]="{'background-color': (event.sub_category_name === 'Klanten') ? '#E7B26C' : '#EDC691'}">
                                        <img [src]="'assets/images/events-icon.png'" class="mainicon">
                                        <span class="text-enquete cursor-pointer">{{event?.sub_category_name}}</span>
                                        <a routerLink="/events-detail/{{event.id}}" class="arrow"></a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- <div class="contentbottomsection">
                    <div class="tabwrapper">
                        <h3 class="sectitle bordermarron">
                            <div class="wrap">
                                ALLE EVENTS
                            </div>
                        </h3>
                        <div class="tab-data event-tab-data main-tab">
                            <app-categorytabs [categoryType]="'event_category_name'" [categories]="eventCategory">
                            </app-categorytabs>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>