<div id="wrapper">
    <div id="main" class="maincontent">
        <div id="primary" class="content-area one-column">
            <div id="content" class="site-content">
                <div class="contenttopsection">
                    <h3 class="sectitle bordermain">
                        <div class="wrap cursor-pointer">
                            {{'your_opinion_is_important' | translate}}
                        </div>
                    </h3>
                    <div *ngIf="isLoading" style="left: 50%;top: 45%;position: fixed">
                        <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
                        </mat-progress-spinner>
                    </div>
                    <ul class="postlisting mainlisting bordermain">
                        <div *ngIf="status === 0" style="display: flex; justify-content: center; align-items: center">
                            {{'no_records_found' | translate}}
                        </div>
                        <div *ngIf="surveyList?.length === 0"
                            style="display: flex; justify-content: center; align-items: center">
                            {{'no_records_found' | translate}}
                        </div>
                        <li *ngFor="let survey of surveyList">
                            <div [ngStyle]="{ 'background-color': survey?.is_read == 1 ? '#E5ECF3' : '#BECF59'}" class="pd-10">
                                <div class="wrap mainpost" routerLink="/survey-detail/{{survey.id}}" *ngIf="categoryId !== '2'">
                                    <div class="postleft">
                                        <figure class="postimg newsimg" *ngIf="survey?.image">
                                            <img [src]="survey?.image" alt="">
                                        </figure>
                                        <div class="postdetails postinformation">
                                            <ul class="postdatecat">
                                                <li class="cursor-pointer" [ngStyle]="{ 'color': survey?.is_read == 0 ? '#038544' : '#000'}">{{survey?.created_at}}</li>
                                            </ul>
                                            <h4>
                                                <a [ngStyle]="{ 'color': survey?.is_read == 0 ? '#038544' : '#000'}" >{{survey?.title}}</a>
                                            </h4>
                                            <div [ngStyle]="{ 'color': survey?.is_read == 0 ? '#038544' : '#000'}">{{survey?.short_description}}    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="postright mainblue">
                                        <img [src]="'assets/images/enquete.png'" class="mainicon">
                                        <span class="text-enquete cursor-pointer">{{'survey' | translate}}</span>
                                        <a class="arrow" routerLink="/survey-detail/{{survey.id}}"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="wrap" routerLink="/survey-result/{{survey.id}}" *ngIf="categoryId === '2'">
                                <div class="postleft">
                                    <figure class="postimg newsimg" *ngIf="survey?.image">
                                        <img [src]="survey?.image" alt="">
                                    </figure>
                                    <div class="postdetails">
                                        <ul class="postdatecat" style="margin-left: 10px">
                                            <li class="cursor-pointer">{{survey?.created_at}}</li>
                                        </ul>
                                        <h4 class="cursor-pointer" style="margin-left: 10px"><a>{{survey?.title}}</a>
                                        </h4>
                                        <p class="cursor-pointer" style="margin-left: 10px">
                                            {{survey?.short_description}}
                                        </p>
                                    </div>
                                </div>
                                <div class="postright" style="background-color:rgb(46, 46, 175)">
                                    <img [src]="'assets/images/enquete.png'" class="mainicon">
                                    <span class="text-enquete cursor-pointer">{{'results' | translate}}</span>
                                    <a class="arrow" routerLink="/survey-result/{{survey.id}}"></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- <div class="contentbottomsection">
                    <div class="tabwrapper">
                        <h3 class="sectitle bordermain">
                            <div class="wrap cursor-pointer">
                                {{'thankyou_for_cooperation' | translate}}
                            </div>
                        </h3>
                        <div class="tab-data survey-tab-data main-tab">
                            <app-categorytabs [categoryType]="'survey_category_name'" [categories]="surveyCategory">
                            </app-categorytabs>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>