import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @Input() backgroundColor;
  @Input() labels;
  @Input() percentage;
  data: any[] = [];
  public chartType = 'horizontalBar';
  public chartDatasets: Array<any> = [
  ];
  public chartLabels: Array<any> = this.labels;

  public chartColors: Array<any> = [
    {
      backgroundColor: this.backgroundColor,
      borderColor: this.backgroundColor,
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  constructor() { }

  ngOnInit(): void {
    this.data = this.percentage;
    this.chartDatasets = [
      {
        data: this.data,
        label: ''
      }];
    this.chartColors = [{
      backgroundColor: this.backgroundColor,
      hoverBackgroundColor: this.backgroundColor,
      borderWidth: 2,
    }];
    this.chartLabels = this.labels;
  }
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

}
