import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, window } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyService } from '../survey.service';
@Component({
  selector: 'app-survey-detail',
  templateUrl: './survey-detail.component.html',
  styleUrls: ['./survey-detail.component.scss']
})
export class SurveyDetailComponent implements OnInit {
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  userId: any;
  isLoading = false;
  surveyId: any;
  status: any;
  surveyDetail: any;
  answers: any = {};
  window = window as any;
  constructor(
    private _appService: AppServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _surveyService: SurveyService
  ) {
    this._unsubscribeAll = new Subject();
    this.activatedRoute.params.subscribe(id => {
      this.surveyId = id.id;
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('userData')) {
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    }
    this.getSurveyDetails();
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
      this.language = language;
      if (localStorage.getItem('language') !== language) {
        this.getSurveyDetails();
      }
    });
  }
  getSurveyDetails() {
    if (!this.userId) { return; }
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('survey_id', this.surveyId);
    this.isLoading = true;
    this._surveyService.getSurveyDetail(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      this.status = data.status;
      if (data.data)
        this.surveyDetail = data.data;
      this.surveyReadById();
      if (this.surveyDetail.is_fill === 1) {
        this.getFilledSurveyDetails();
      } else {
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
    });
  }
  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getFilledSurveyDetails() {
    if (!this.userId) { return; }
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('survey_id', this.surveyId);
    this._surveyService.getFilledSurveyDetails(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      this.status = data.status;
      this.surveyDetail = data.data;
    }, error => {
      this.isLoading = false;
    });
  }

  // handleQuestionMap(question) {
  //   if (question.input_type === 5) {
  //     const options = question.options.map((option: any) => {
  //       let answer = 0;
  //       if (this.answers[question.id] && this.answers[question.id].includes(option.id)) {
  //         answer = 1;
  //       }
  //       return { ...option, answer };
  //     });
  //     return { ...question, options };
  //   } else if (question.input_type === 7) {
  //     const options = question.options.map((option: any) => {
  //       let answer = 0;
  //       if (this.answers[question.id]) {
  //         answer = 1;
  //       }
  //       return { ...option, answer };
  //     });
  //     return { ...question, options };
  //   } else if (question.input_type === 1) {
  //     return { ...question, answer: this.answers[question.id] || 0 };
  //   }
  // }

  submit() {
    let ques: any[];
    ques = this.surveyDetail.form_data.filter((ques: any) => ques.input_required === 1);

    this.surveyDetail.subtitle.forEach((q: any) => {
      ques.push(...q.question.filter((question: any) => question.input_required === 1));
    });
    const notAttempted = ques.filter((q: any) => this.answers[q.id] === undefined);
    const notAttemptedIds = notAttempted.map((q: any) => q.id);
    this._surveyService.unAttemptedQuestions.next(notAttemptedIds);
    if (notAttemptedIds.length === 0) {
      const formDataValues = this.surveyDetail.form_data.map((q: any) => {
        if (q.input_type === 5) {
          const options = q.options.map((option: any) => {
            if (this.answers[q.id] && this.answers[q.id].includes(option.id)) {
              return { ...option, answer: 1 }
            } else {
              return { ...option, answer: 0 }
            }
          })
          return { ...q, options }
        } else if (q.input_type === 7) {
          const options = q.options.map((option: any) => {
            if (this.answers[q.id] === option.id) {
              return { ...option, answer: 1 }
            } else {
              return { ...option, answer: 0 }
            }
          })
          return { ...q, options }
        } else if (q.input_type === 1)
          return { ...q, answer: this.answers[q.id] || 0 };
      })
      const subtitleValues = this.surveyDetail.subtitle.map((section: any) => {
        const question = section.question.map((question: any) => {
          if (question.input_type === 5) {
            const options = question.options.map((option: any) => {
              if (this.answers[question.id] && this.answers[question.id].includes(option.id)) {
                return { ...option, answer: 1 }
              } else {
                return { ...option, answer: 0 }
              }
            })
            return { ...question, options }
          } else if (question.input_type === 7) {
            const options = question.options.map((option: any) => {
              if (this.answers[question.id] === option.id) {
                return { ...option, answer: 1 }
              } else {
                return { ...option, answer: 0 }
              }
            })
            return { ...question, options }
          } else if (question.input_type === 1)
            return { ...question, answer: this.answers[question.id] || 0 };
        })
        return { ...section, question }
      })
      this.isLoading = true;
      const body = {
        user_id: this.userId,
        survey_id: this.surveyDetail.id,
        language: this.language,
        survey_date: this.surveyDetail.updated_at,
        data: {
          id: this.surveyDetail.id,
          title: this.surveyDetail.title,
          description: this.surveyDetail.description,
          short_description: this.surveyDetail.short_description,
          form_data: formDataValues,
          subtitle: subtitleValues
        }
      };
      this._surveyService.submitSurvey(body).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
        if (this.language === 'nl' && data.message === 'De enquête werd ge-update') {
          this.window.location.reload();
        }
        if (this.language === 'fr' && data.message === "L'enquête est mise à jour") {
          this.window.location.reload();
        }
        if (data.status === 1) {
          this.isLoading = false;
          this._snackBar.open('Survey submitted successfully', 'Close', { duration: 2000 });
          this.router.navigate(['/survey']);
        }
      });
    }
  }

  getAnswers(event, type?) {
    let question: any;
    if (type) {
      this.surveyDetail.subtitle.forEach((q: any) => {
        const foundQuestion = q.question.find((ques: any) => ques.id == event.questionId);
        if (foundQuestion) {
          question = foundQuestion;
        }
      });
    } else {
      question = this.surveyDetail.form_data.find((question: any) => question.id == event.questionId);
    }
    this.answers[event.questionId] = event.answer;
  }

  surveyReadById() {
    const formData: any = new FormData();
    formData.append('survey_id', this.surveyId);
    formData.append('user_id', this.userId);
    formData.append('language', this.language);
    this._surveyService.surveyReadById(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    })
  }


}
