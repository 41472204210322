<div>
  <h5 class="cursor-pointer" *ngIf="!isRequired">{{index}}.{{question.input_label}}</h5>
  <h5 class="cursor-pointer" *ngIf="isRequired" style="display: contents;">{{index}}.{{question.input_label}}</h5>
  <span *ngIf="isRequired" style="color: red;margin-left: 5px;">*</span>
  <p *ngIf="isEditAccess">(Select max. {{maxOptionSelect}} options)</p>
  <img [src]="question?.image" height="200" width="200" *ngIf="question?.image">

</div>
<div class="example-section" *ngIf="isEditAccess">
  <mat-checkbox class="example-margin" [value]="option.id" *ngFor="let option of options" (change)="onChange($event)">
    <img src="{{option.image}}" *ngIf="option.image" width="100" height="100">
    <p class="font-20">{{option.text}}</p>
  </mat-checkbox>
</div>
<div class="example-section" *ngIf="!isEditAccess">
  <mat-checkbox class="example-margin" [value]="option.id" *ngFor="let option of options" [checked]="option.checked"
    disabled>
    <img src="{{option.image}}" *ngIf="option.image" width="100" height="100">
    <p class="font-20">{{option.text}}</p>
  </mat-checkbox>
</div>
<mat-error *ngIf="showError">
  This question is required
</mat-error>
