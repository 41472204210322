import { Component, OnInit, Input, SimpleChanges, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { AppServiceService } from '../app-service.service';
import { takeUntil } from 'rxjs/operators';
import { SurveyService } from '../survey.service';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss']
})
export class SurveyListComponent implements OnInit {
  @Input() type;
  @Input() listType;
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  surveyList: any;
  status: any;
  pageNo: number = 1;
  totalPages: number;
  isLoading: boolean = false;
  userId: any;
  tab_type: any = '';
  constructor(
    private _appService: AppServiceService,
    private _surveyService: SurveyService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (localStorage.getItem('userData'))
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    this.getAllSurvey();
    // this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
    //   this.language = language;
    //   if (localStorage.getItem('language') !== language) {
    //     localStorage.setItem('language', language);
    //     this.getAllSurvey();
    //   }
    // })
  }
  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type.currentValue !== changes.type.previousValue) {
      this.type = changes.type.currentValue;
      if (this.type == 2) {
        this.tab_type = 1;
      } else {
        this.tab_type = '';
      }
      this.getAllSurvey();
    }
  }
  /**
    * 
    * @param event 
    * For Infinite scrolling of message
    */
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (event.target.scrollingElement.scrollTop + event.target.scrollingElement.offsetHeight >= event.target.scrollingElement.scrollHeight && this.pageNo < this.totalPages) {
      this.pageNo += 1;
    }
  }
  /**
* Get Survey List
*/
  getAllSurvey(type?) {
    if (!this.userId) return;
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('page_no', this.pageNo);
    formData.append('tab_type', this.tab_type);
    this.isLoading = true;
    this._surveyService.getSurveyList(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      this.totalPages = data.total_count;
      if (data.data)
        this.surveyList = data.data;
      this.status = data.status;
      this.isLoading = false;
      if (type) {
        this.surveyList.push(...data.data);
      } else {
        this.surveyList = data.data;
      }
    }, error => {
      this.isLoading = false;
    })
  }


}
