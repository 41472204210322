import { Component, OnInit, HostListener } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: 'app-homescreen',
  templateUrl: './homescreen.component.html',
  styleUrls: ['./homescreen.component.scss']
})
export class HomescreenComponent implements OnInit {

  userId: any;
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  pageNo: number = 1;
  dashboardList: any;
  dashboardData: any[] = [];
  isLoading: boolean = false;
  totalPages: number;
  status: any;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  noRecordsFound: boolean = false;
  constructor(
    private _appService: AppServiceService,
    private translate: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    let templan = localStorage.getItem("language");
    if (templan == null || templan == "") {
      translate.use("nl");
      localStorage.setItem("language", "nl");
    } else {
      translate.use(templan);
    }
  }

  ngOnInit(): void {
    this.getDashboardList();
    // this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
    //   this.language = language;
    //   if (localStorage.getItem('language') !== language) {
    //     this.getDashboardList();
    //   }
    // })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getDashboardList(type?) {
    this.userId = JSON.parse(localStorage.getItem('userData')).id;
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('page_no', this.pageNo);
    this.isLoading = true;
    this._appService.getDashboardList(formData).subscribe((data: any) => {
      if (data) {
        if (data.status === 0) {
          this.noRecordsFound = true;
          this.isLoading = false;
          this.dashboardList = {
            message_unread_count: 0,
            event_unread_count: 0,
            survey_unread_count: 0,
          }
        } else {
          if (type) {
            this.dashboardData.push(...data.data);
          } else {
            this.dashboardData = data.data;
          }
          this.dashboardList = data;
          this.dashboardData = this.dashboardData.map((data: any) => {
            let iconType = '';
            let backgroundcolor = '';
            let route = ''
            switch (data.type) {
              case 1:
                iconType = 'assets/images/calender.png';
                backgroundcolor = '#abc32b';
                route = 'news-detail';
                break;
              case 2:
                iconType = 'assets/images/whitecallimg.png';
                backgroundcolor = '#e09d49';
                route = 'events-detail';
                break;
              case 3:
                iconType = 'assets/images/enquete.png';
                backgroundcolor = '#005382';
                route = 'survey-detail';
                break;
              default:
                break;
            }
            return { ...data, iconType, backgroundcolor, route };
          })
          this.isLoading = false;
          this.status = data.status;
          this.totalPages = data.total_count;
        }
      }
    }, error => {
      this.isLoading = false;
    })
  }
  /**
     * 
     * @param event 
     * For Infinite scrolling of message
     */
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (event.target.scrollingElement.scrollTop + event.target.scrollingElement.offsetHeight >= event.target.scrollingElement.scrollHeight && this.pageNo < this.totalPages) {
      this.pageNo += 1;
      this.getDashboardList('scroll');
    }
  }
}
