<div style="position: absolute;left: 45%;top: 50%;z-index: 999;" *ngIf="isLoading">
    <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div class="loginwrapper">
    <div class="loginwrap">
      <figure id="loginlogo" class="main-logo-with-dropdwon">
        <img src="assets/images/loginlogo.png" alt="Logo">
        <mat-form-field appearance="fill" class="angular-material-dropdown">
          <mat-select (selectionChange)="langChange()" [(ngModel)]="language">
            <mat-option select value="nl">NL</mat-option>
            <mat-option value="fr">FR</mat-option>
          </mat-select>
        </mat-form-field>
      </figure>
      <form [formGroup]="forgotPasswordForm">
        <div class="logincontent">
          <h3>{{'welcome' | translate}}</h3>
          <div class="loginformwrapper">
            <div class="form-block">
              <div class="form-group email-div">
                <input type="email" name="email" formControlName="email" placeholder="{{'email' | translate}}" [pattern]="emailPattern" class="form-control">
                <mat-icon *ngIf="emailValidated" class="email-done">done_all</mat-icon>

              </div>
              <p class="display-error" *ngIf="forgotPasswordForm.controls['email'].errors?.required && (forgotPasswordForm.controls['email'].touched || submitted)">Email is required!
              </p>
              <p class="display-error" *ngIf="forgotPasswordForm.controls['email'].errors?.email">Enter a valid email</p>

              <div class="form-group submitwrapper" style="text-align: center">
                <button type="submit" (click)="forgotPassword()">
                  {{'next' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
