import { Component, OnInit, HostListener } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SurveyService } from '../survey.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  userId: any;
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  pageNo: number = 1;
  totalPages: number;
  surveyList: any;
  status: any;
  isLoading: boolean = false;
  surveyEvent: any;
  surveyCategory: any[] = [
    { id: 1, survey_category_name: "survey", sub_category: [] },
    { id: 2, survey_category_name: "results", sub_category: [] }
  ];
  tab_type: any = '';
  categoryId = '';
  noRecordsFound: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private _appService: AppServiceService,
    private _surveyService: SurveyService
  ) {
    this.route
      .queryParams
      .subscribe(params => {
        if (params.categoryID) {
          this.categoryId = params.categoryID;
          this.getSurveyList();
        }
      });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (localStorage.getItem('userData')) {
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    }
    this.getSurveyList();
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
      this.language = language;
      if (localStorage.getItem('language') !== language) {
        this.getSurveyList();
      }
    })
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * Get Survey List
   */
  getSurveyList(type?) {
    if (!this.userId) return;
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('page_no', this.pageNo);
    // formData.append('tab_type', this.tab_type);
    if (this.categoryId) {
      if (this.categoryId === '1')
        formData.append('tab_type', this.tab_type);
      else
        formData.append('tab_type', '1');
    }
    this.isLoading = true;
    this._surveyService.getSurveyList(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      if(data) {
        if (data.status === 0) {
          this.noRecordsFound = true;
          this.isLoading = false;
        } else {
          this.status = data.status;
          this.totalPages = data.total_count;
          this.isLoading = false;
          // this.surveyEvent = data.surveyEvent;
          if (type) {
            this.surveyList.push(...data.data);
          } else {
            this.surveyList = data.data;
          }

          this.surveyList = this.surveyList.map((data: any) => {
            let iconType = '';
            let backgroundcolor = '';
            let route = ''
            iconType = 'assets/images/enquete.png';
            backgroundcolor = '#005382';
            route = 'survey-detail';
            return { ...data, iconType, backgroundcolor, route };
          });
          this.isLoading = false;
          this.status = data.status;
          this.totalPages = data.total_count;
        }
      }
    }, error => {
      this.isLoading = false;
    })
  }
  /**
     * 
     * @param event 
     * For Infinite scrolling of message
     */
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (event.target.scrollingElement.scrollTop + event.target.scrollingElement.offsetHeight >= event.target.scrollingElement.scrollHeight && this.pageNo < this.totalPages && !this.isLoading) {
      this.pageNo += 1;
      this.getSurveyList('scroll');
    }
  }

}
