import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyService } from '../survey.service';
@Component({
  selector: 'app-survey-option-multiple',
  templateUrl: './survey-option-multiple.component.html',
  styleUrls: ['./survey-option-multiple.component.scss']
})
export class SurveyOptionMultipleComponent implements OnInit {
  @Input() question;
  @Input() answer;
  @Input() isEditAccess;
  @Input() index;
  @Input() isRequired;
  @Input() maxOptionSelect;
  @Input() options;
  @Output('answerValue') answerValue: EventEmitter<any> = new EventEmitter();
  checked = false;
  private _unsubscribeAll: Subject<any>;
  language = localStorage.getItem('language');
  userId: any;
  showError: boolean = false;
  selectedOptions = new FormControl();
  selectedAnswerValues: any[] = [];
  defaultChecked: any = [];
  selectedId: any[] = [];
  counter = 0;
  constructor(
    private _appService: AppServiceService,
    private _snackBar: MatSnackBar,
    private _surveyService: SurveyService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (localStorage.getItem('userData')) {
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    }
    this._surveyService.unAttemptedQuestions.pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
      let checkAttempted = response.includes(this.question.id);
      if (checkAttempted) {
        this.showError = true;

      } else {
        this.showError = false;
      }
    });
    this.options = this.options.map((option: any) => ({
      ...option,
      checked: option.answer == 1
    }))
    this.defaultChecked = this.options.filter(option => option.answer == 1).map(option => option.id);
  }
  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  onChange(event) {
    if (this.maxOptionSelect <= this.counter) {
      this._snackBar.open('Cannot select more than the maximum limit', 'Close', { duration: 2000 });
      return event.source.checked = false;
    }

    if (!this.selectedId.includes(event.source.value)) {
      this.selectedId.push(event.source.value);
    } else {
      this.selectedId = this.selectedId.filter(id => id !== event.source.value);
    }

    if (event.checked)
      this.counter += 1;
    else
      this.counter -= 1;
    this.answerValue.emit({ questionId: this.question.id, answer: this.selectedId });
  }
}
