<mat-sidenav-container class="container">
    <div id="sidemenu" class="home" style="background: white; width:275px;">
        <div id="wrapper">
            <div>
                <div class="navTop" style="height: 60px;margin-left: 129px;">
                    <figure class="mainmenulogo">
                        <img [src]="'assets/images/mainmenulogo.png'" alt="">
                    </figure>
                </div>
                <div class="mainmenubotttom" style="display:flex;flex-direction: column;justify-content:space-between">
                    <div>
                        <mat-accordion #accordion="matAccordion">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header class="bggreen">
                                    <mat-panel-title>
                                        <img style="height: 20px;margin-right: 10px;"
                                            [src]="'assets/images/whitecallimg.png'">
                                        {{'news' | translate}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ul class="mainmenutopmenu">
                                    <li class="bggreen" *ngFor="let item of messageCategory"
                                        (click)="routeToSelectedCategory('news', item)">
                                        <a class="cursor-pointer"
                                            [ngStyle]="{'background-color': getColor(item.message_category_name)}">
                                            {{item?.message_category_name | uppercase}}
                                        </a>
                                    </li>
                                </ul>
                                <!-- <mat-expansion-panel class="bggreen" *ngFor="let item of messageCategory">
                                    <mat-expansion-panel-header
                                        [ngStyle]="{'background-color': (item.message_category_name === 'Taking Care') ? 'white' : 'rgb(238, 251, 167)'}">
                                        <mat-panel-title (click)="routeToSelectedCategory('news', item)"
                                            [ngStyle]="{'color': (item.message_category_name === 'Taking Care') ? 'black' : 'white'}">
                                            {{item?.message_category_name | uppercase}}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <p *ngFor="let category of item?.sub_category" class="cursor-pointer"
                                        (click)="routeToSelectedCategory('news', category, item)"
                                        [ngStyle]="{'background-color': category.message_category_name === 'HR news' ? '#cfdb85' : 'rgb(238, 251, 167)' }">
                                        {{category?.message_category_name | uppercase}}
                                    </p>
                                </mat-expansion-panel> -->
                            </mat-expansion-panel>
                            <mat-expansion-panel #panel>
                                <mat-expansion-panel-header class="bgdarkyellow">
                                    <mat-panel-title>
                                        <img style="height: 20px;margin-right: 10px;"
                                            [src]="'assets/images/calender.png'">
                                        {{'events' | translate}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p *ngFor="let category of eventCategory" class="cursor-pointer"
                                    (click)="routeToSelectedCategory('events', category)"
                                    [ngStyle]="{'background-color': (category.event_category_name === 'Klanten' ) ? '#E6B16B' : '#EEC692'}">
                                    {{category?.event_category_name | translate}}
                                </p>
                            </mat-expansion-panel>
                            <mat-expansion-panel #panel>
                                <mat-expansion-panel-header class="bgdarkblue">
                                    <mat-panel-title>
                                        <img style="height: 20px;margin-right: 10px;"
                                            [src]="'assets/images/enquete.png'">
                                        {{'survey' | translate}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p *ngFor="let category of surveyCategory" class="cursor-pointer"
                                    (click)="routeToSelectedCategory('survey', category)"
                                    [ngStyle]="{'background-color': (category.survey_category_name === 'survey' ) ? '#005382' : '#70ADC9', 'color': 'white'}">
                                    {{category?.survey_category_name | translate}}
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <ul class="mainmenutopmenu">
                            <li class="whoiswho bggrey" (click)="routeToWieListing()">
                                <a title=" {{'who_is_who' | translate}}" class="cursor-pointer">
                                    <img style="height: 20px;margin-right: 5px;"
                                        [src]="'assets/images/wieiswie-icon.png'">
                                    {{'who_is_who' | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="mainmenubottommenu">
                        <mat-accordion>
                            <mat-expansion-panel #panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <img style="height: 20px;margin-right: 10px;"
                                            [src]="'assets/images/Logo WECARE_V2.png'">
                                        {{'my_app' | translate}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p (click)="routeToProfile()" class="cursor-pointer">{{'profile' | translate}}</p>
                                <p (click)="changeSettings()" class="cursor-pointer">{{'settings' | translate}}</p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <li style="list-style-type: none;">
                            <a (click)="logout()" title="UITLOGGEN" class="cursor-pointer">
                                {{'logout' | translate}}
                            </a>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-sidenav-container>