<div id="main" class="maincontent" style="margin-top:65px">
    <div id="primary" class="content-area one-column">
        <div id="content" class="site-content">
            <div class="bordergray">
                <h3 class="sectitle personborder">
                    <div class="searchmain">
                        <div class="wrap topnav">
                            <div class="search-container">
                                <form action="#" class="searchmargin">
                                    <input type="text" placeholder="{{'search' | translate}}" [(ngModel)]="searchText"
                                        name="search" class="serachbg mainsearch" (keydown.enter)="getAllUsers()">
                                    <button type="submit">
                                        <img [src]="'assets/images/Searching.png'" class="searchimg">
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--/.wrap-->
                </h3>
                <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
                    </mat-progress-spinner>
                </div>
                <ul class="postlisting bordergray wieiswielisting">
                    <div class="cursor-pointer" *ngIf="status === 0"
                        style="display: flex; justify-content: center; align-items: center">
                        {{'no_records_found' | translate}
                    </div>
                    <li *ngFor="let user of users">
                        <div class="wrap" routerLink="/wie-is-wie-detail/{{user.id}}">
                        <div class="postleft">
                            <figure class="postimg newsimg">
                                <img [src]="user?.profile_image ? user?.profile_image : defaultAvatar" alt="">
                            </figure>
                            <div class="postdetails">
                                <div class=" details">
                                    <h4><a class="cursor-pointer">{{user?.first_name}} {{user?.last_name}}</a></h4>
                                    <p class="cursor-pointer">{{user?.user_department_name}}</p>
                                </div>
                            </div>
                        </div>
                        <!--/.postleft-->
                        <div class="postright arrow-main">
                            <a routerLink="/wie-is-wie-detail/{{user.id}}"><img [src]="'assets/images/arrow.png'"
                                    class="detailsarrow">
                            </a>
                        </div>
                    </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>