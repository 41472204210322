import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-detail-page',
  templateUrl: './detail-page.component.html',
  styleUrls: ['./detail-page.component.scss']
})
export class DetailPageComponent implements OnInit {
  userId;
  newsId: any;
  currentNews: any;
  isLoading: boolean = false;
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  status: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private _appService: AppServiceService
  ) {
    this._unsubscribeAll = new Subject();
    this.activatedRoute.params.subscribe(id => {
      this.newsId = id.did;
    });
  }

  ngOnInit(): void {
    this.getSelectedNews();
    // this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
    //   this.language = language;
    //   if (localStorage.getItem('language') !== language) {
    //     this.getSelectedNews();
    //   }
    // })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getSelectedNews() {
    this.userId = JSON.parse(localStorage.getItem('userData')).id;
    const formData: any = new FormData();
    formData.append('user_id', this.userId);
    formData.append('message_id', this.newsId);
    formData.append('language', this.language);
    this.isLoading = true;
    this._appService.getNewsById(formData).subscribe((response: any) => {
      this.isLoading = false;
      this.status = response.status;
      if (response.data)
        this.currentNews = response.data[0];
        console.log(this.currentNews);
        if(this.currentNews.url.includes('shorts')) {
          this.currentNews.url = this.currentNews.url.replace('shorts', 'embed')
        }
    }, error => {
      this.isLoading = false;
    })
    this._appService.readNewsById(formData).subscribe((response: any) => {
    }, error => {
      this.isLoading = false;
    })
  }
}
