<div>
  <h5 class="cursor-pointer" *ngIf="!isRequired">{{index}}.{{question?.input_label}}</h5>
  <h5 class="cursor-pointer" *ngIf="isRequired" style="display: contents;">{{index}}.{{question.input_label}}</h5>
  <span *ngIf="isRequired" style="color: red;margin-left: 5px;">*</span>
  <img [src]="question?.image" height="200" width="200" *ngIf="question?.image">
</div>
<div *ngIf="isEditAccess" style="margin-top:10px">
  <mat-radio-group [(ngModel)]="selectedOption" aria-label="Select an option">
    <mat-radio-button *ngFor="let option of options" (change)="checkSelected(option)" [value]="option.id">
      {{option?.text}}</mat-radio-button>
  </mat-radio-group>
</div>
<div *ngIf="!isEditAccess" style="margin-top:10px">
  <mat-radio-group (change)="checkSelected($event)" [ngModel]="selectedValues" aria-label="Select an option">
    <mat-radio-button *ngFor="let option of options" [value]="option.id" disabled>
      {{option?.text}}
    </mat-radio-button>
  </mat-radio-group>
</div>
<mat-error *ngIf="showError">
  This question is required
</mat-error>
