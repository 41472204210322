<div id="main" #maincontent class="maincontent">
  <div id="primary" class="content-area one-column">
    <div id="content" class="site-content">
      <div class="contenttopsection" style="padding-top:70px">
        <h3 class="sectitle" style="margin-top: 45px;">
          <div class="wrap textwrap">
            {{'all_records' | translate}}
          </div>
        </h3>
        <ul class="postlisting">
          <div class="wrap" *ngIf="status === 0">
            {{'no_records_found' | translate}}
          </div>
          <div class="wrap" *ngIf="allNews?.data?.length === 0">
            {{'no_records_found' | translate}}
          </div>
          <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
            <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </div>
          <li *ngFor="let item of allNews">
            <div class="pd-10" [ngStyle]="{ 'background-color': item.is_read == 1 ? '#E5ECF3' : '#BECF59'}">
              <div class="wrap mainpost" routerLink="/news-detail/{{item.id}}">
                <div class="postleft">
                  <figure class="postimg mainimg" *ngIf="item.image">
                    <img src="{{item?.image}}" alt="newsimg01">
                  </figure>
                  <figure class="postimg" *ngIf="!item.image && item.youtube_thumbnail">
                    <img src="{{item.youtube_thumbnail}}" alt="newsimg01">
                  </figure>
                  <div class="postdetails postinformation">
                    <ul class="postdatecat">
                      <li [ngStyle]="{ 'color': item?.is_read == 0 ? '#038544' : '#000'}">{{item?.created_at}}</li>
                      <li [ngStyle]="{ 'color': item?.is_read == 0 ? '#038544' : '#000'}">{{item?.category}}</li>
                    </ul>
                    <h4>
                      <a [ngStyle]="{ 'color': item?.is_read == 0 ? '#038544' : '#000'}">{{item?.title}}</a>
                    </h4>
                    <div [ngStyle]="{ 'color': item?.is_read == 0 ? '#038544' : '#000'}">{{item?.short_description}}</div>
                  </div>
                </div>
                <div class="postright" [ngStyle]="{'background-color': getColor(item.sub_category_name)}">
                  <span class="catmsgicon">{{item?.sub_category_name}}</span>
                  <a routerLink="/news-detail/{{item.id}}" class="arrow"></a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
