<div style="height:100vh" [ngStyle]="{'background-color' :(backgroundcolor ? backgroundcolor : '')}">
  <div>
    <app-header [snav]="snav" *ngIf="showHeader"></app-header>
  </div>
  <mat-sidenav-container mode="side" class="example-sidenav-container">
    <mat-sidenav #snav>
      <app-side-menu [snav]="snav"></app-side-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>