<div>
    <ng-container *ngIf="settings">
        <h1 matDialogTitle>Confirm</h1>
        <div mat-dialog-content>Are you sure you want to logout?</div>
        <div mat-dialog-actions class="pt-24" style="margin-top:20px">
            <button mat-raised-button class="mat-primary mr-16" (click)="dialogRef.close(true)">Confirm</button>
            <button mat-button (click)="dialogRef.close(false)">Cancel</button>
        </div>
    </ng-container>
    <ng-container *ngIf="!settings">
        <h1 matDialogTitle>Change Language Settings</h1>
        <div mat-dialog-content>Select a language</div>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="selectedLanguage">
            <mat-radio-button class="example-radio-button" *ngFor="let language of languages" [value]="language.value">
                {{language?.name}}
            </mat-radio-button>
        </mat-radio-group>
        <div mat-dialog-actions class="pt-24" style="margin-top:20px">
            <button mat-raised-button class="mat-primary mr-16"
                (click)="dialogRef.close({status:true, language:selectedLanguage})">Confirm</button>
            <button mat-button (click)="dialogRef.close(false)">Cancel</button>
        </div>
    </ng-container>
</div>