import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppServiceService } from 'src/app/app-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  public confirmMessage: string;
  type: any;
  settings: boolean = false;
  selectedLanguage;
  languages = [
    { name: 'NL', value: 'nl' },
    { name: 'FR', value: 'fr' },
  ];
  private _unsubscribeAll: Subject<any>;
  constructor(
    private _appService: AppServiceService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.type = data.type;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (this.type !== "settings") {
      this.settings = true;
    } else {
      this.settings = false
    }
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe((language) => {
      this.selectedLanguage = localStorage.getItem('language');
    })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


}
