import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() snav: MatSidenav;
  route: string;
  constructor(
    private router: Router,
  ) {
  }
  public backgroundcolor = '#abc32b';
  public headerTitle = 'News';
  public headerIcon = 'newsicon';
  public fontColor = 'white';

  ngOnInit(): void {
    this.handleHeaderColorChange(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.handleHeaderColorChange(event.url);
      }
    });
  }

  handleHeaderColorChange(url) {
    const path = url.split("?")[0];
    const newURL = path.split('/')[1];
    switch (newURL) {
      case 'home':
        this.backgroundcolor = '#abc32b';
        this.headerTitle = 'news';
        this.headerIcon = 'icon newsicon';
        this.fontColor = 'white';
        break;
      case 'news-detail':
        this.backgroundcolor = '#abc32b';
        this.headerTitle = 'news_detail_header';
        this.headerIcon = '';
        this.fontColor = 'white';
        break;
      case 'wie-is-wie':
        this.backgroundcolor = '#6e6f70';
        this.headerTitle = 'who_is_who';
        this.headerIcon = 'icon account_box';
        this.fontColor = 'white';
        break;
      case 'wie-is-wie-detail':
        this.backgroundcolor = '#6e6f70';
        this.headerTitle = 'wie_is_wie_detail_header';
        this.headerIcon = '';
        this.fontColor = 'white';
        break;
      case 'events-detail':
        this.backgroundcolor = '#e09d49';
        this.headerTitle = 'event_detail_header';
        this.headerIcon = ' ';
        this.fontColor = 'white';
        break;
      case 'events':
        this.backgroundcolor = '#e09d49';
        this.headerTitle = 'events';
        this.headerIcon = 'today';
        this.fontColor = 'white';
        break;
      case 'homescreen': {
        this.backgroundcolor = 'white';
        this.headerTitle = 'Breaking News';
        this.headerIcon = '';
        this.fontColor = '#005382';
        break;
      }
      case 'survey':
        this.backgroundcolor = '#005382';
        this.headerTitle = 'survey';
        this.headerIcon = 'enquete-icon';
        this.fontColor = 'white';
        break;
      case 'survey-detail':
        this.backgroundcolor = '#005382';
        this.headerTitle = 'survey_detail_header';
        this.headerIcon = '';
        this.fontColor = 'white';
        break;
      case 'survey-result':
        this.backgroundcolor = '#005382';
        this.headerTitle = 'results';
        this.headerIcon = '';
        this.fontColor = 'white';
        break;
      case 'page-not-found':
        this.backgroundcolor = 'black';
        this.headerTitle = 'Page not found';
        this.headerIcon = '';
        this.fontColor = 'white';
        break;
      default:
        this.backgroundcolor = 'black';
        this.headerTitle = 'Page not found';
        this.headerIcon = '';
        this.fontColor = 'white';
        break;
    }
  }

  toggleSideDrawer(state?) {
    if (state === 'close' && !this.snav.opened) {
      return;
    }
    this.snav.toggle();
  }
}

