<div style="position: absolute;left: 45%;top: 50%;z-index: 999;" *ngIf="isLoading">
    <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div class="loginwrapper">
    <div class="loginwrap">
      <figure id="loginlogo" class="main-logo-with-dropdwon">
        <img src="assets/images/loginlogo.png" alt="Logo">
        <mat-form-field appearance="fill" class="angular-material-dropdown">
          <mat-select (selectionChange)="langChange()" [(ngModel)]="language">
            <mat-option select value="nl">NL</mat-option>
            <mat-option value="fr">FR</mat-option>
          </mat-select>
        </mat-form-field>
      </figure>
      <form [formGroup]="resetPasswordForm">
        <div class="logincontent">
          <h3>{{'welcome' | translate}}
            <span>{{'set_and_confirm_password' | translate}}</span>
          </h3>
          <div class="loginformwrapper">
            <div class="form-block">
              <div class="form-group">
                <input type="password" formControlName="password" name="password" class="form-control"
                  placeholder="{{'password' | translate}}" [pattern]="passwordPattern">
              </div>
              <p class="display-error" *ngIf="resetPasswordForm.controls['password'].errors?.required &&
                (resetPasswordForm.controls['password'].touched || submitted)">
                Password is required
              </p>
              <div class="form-group">
                <input type="password" formControlName="confirmpassword" name="confirmpassword"
                  placeholder="{{'confirm_password' | translate}}" class="form-control" [pattern]="passwordPattern">
              </div>
              <p class="display-error" *ngIf="resetPasswordForm.controls['confirmpassword'].errors?.required && (resetPasswordForm.controls['confirmpassword'].touched || submitted)">
                Confirm Password is required
              </p>
              <p class="display-error" *ngIf="passwordError">
                {{'password_do_not_match' | translate}}
              </p>
              <div class="form-group submitwrapper" style="text-align: center">
                <button type="submit" (click)="resetPassword()">
                  {{'next' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- <span *ngIf="isLoginAccess">Login Success</span> -->
    </div>
  </div>
