
import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { AppServiceService } from 'src/app/app-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../dialog/dialog.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSidenav } from '@angular/material/sidenav';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Input() snav: MatSidenav
  @ViewChildren(MatExpansionPanel) Accordion: QueryList<MatExpansionPanel>
  confirmDialogRef: MatDialogRef<DialogComponent>;
  languageDialogRef: MatDialogRef<DialogComponent>;
  panelOpenState = false;
  userId: any;
  messageCategory;
  sidenav: any;
  eventCategory: any;
  language = localStorage.getItem('language');
  surveyCategory: any[] = [
    { id: 1, survey_category_name: "survey", sub_category: [] },
    { id: 2, survey_category_name: "results", sub_category: [] }
  ];
  isLoading: boolean = false;
  private _unsubscribeAll: Subject<any>;
  constructor(
    public _matDialog: MatDialog,
    private _appService: AppServiceService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService) {
    this._unsubscribeAll = new Subject();
    let templan = localStorage.getItem("language");
    if (templan == null || templan == "") {
      translate.use("nl");
      localStorage.setItem("language", "nl");
    } else {
      translate.use(templan);
    }
  }

  ngOnInit(): void {
    this.getMessageCategory();
    this.getEventCategory();
    this.snav.closedStart.subscribe(() => {
      this.Accordion.map(accordian => accordian.close());
    })
    // this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
    //   this.language = language;
    //   if (localStorage.getItem('language') !== language) {
    //     this.getEventCategory();
    //     this.getMessageCategory();
    //   }
    // })
  }
  /**
   *  Get Message Categories for expansion panel
   */
  getMessageCategory() {
    if (localStorage.getItem('userData')) {
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    }
    const formData: any = new FormData();
    formData.append('user_id', this.userId);
    formData.append('language', this.language);
    this._appService.getMessageCategory(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((messageCategory: any) => {
      if (messageCategory.messageCategory) {
        this.messageCategory = messageCategory.messageCategory.map((message: any) => {
          const subCategory = message.sub_category.map(cat => ({ ...cat }));
          return { ...message, sub_category: subCategory }
        });
      }
    });
  }
  getColor(color) {
    switch (color) {
      case 'Taking Care':
        return '#BECF59';
      case 'HR news':
        return '#DFE8B1';
      case 'Vaillant group news':
        return 'seagreen';
    }
  }


  /**
   * Logout
   */
  logout() {
    this.snav.toggle();
    this.Accordion.map(accordian => accordian.close());
    this.confirmDialogRef = this._matDialog.open(DialogComponent, {
      disableClose: false,
      id: 'delete',
      width: '300px',
      height: '200px',
      data: {
        type: 'logout'
      }
    });
    this.confirmDialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe(result => {
      if (result) {
        this.isLoading = true;
        const formData: any = new FormData();
        formData.append('device_token', '');
        formData.append('user_id', JSON.parse(localStorage.getItem('userData')).id);
        this._appService.logout(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
          this.isLoading = false;
          if(data) {
            if(data.status === 1) {
              localStorage.setItem('userData', '');
              localStorage.setItem('userToken', '');
              //localStorage.clear();
              this._appService.selectedLanguage.next('nl');
              this.router.navigate(['/login']);
            }
          }
        }, error => {
          this.isLoading = false;
        })
        
      }
    })
  }
  /**
   * Change Language
   */
  changeSettings() {
    this.snav.toggle();
    this.Accordion.map(accordian => accordian.close());
    this.languageDialogRef = this._matDialog.open(DialogComponent, {
      disableClose: false,
      id: 'settings',
      width: '300px',
      height: '300px',
      data: {
        type: 'settings'
      }
    })
    this.languageDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (localStorage.getItem('language') !== result.language) {
          this._appService.selectedLanguage.next(result.language);
          localStorage.setItem('language', result.language);
          window.location.reload();
        }
      }
    })
  }

  /****
   * Route to Wie-is-wie Listing Page
   */
  routeToWieListing() {
    this.snav.toggle();
    this.Accordion.map(accordian => accordian.close());
    this.router.navigate(['/wie-is-wie']);
  }

  /****
    * Route to My Profile Page
    */
  routeToProfile() {
    if (localStorage.getItem('userData'))
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    this.snav.toggle();
    this.Accordion.map(accordian => accordian.close());
    this.router.navigate([`/wie-is-wie-detail/${this.userId}`]);
  }
  /**
  *  Get Event Categories for expansion panel
  */
  getEventCategory() {
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    this._appService.getEventCategory(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((event: any) => {
      this.eventCategory = event.eventCategory
    }, error => {
    })
  }
  /**
   *  Route to selected category and subcategory
   * @param type 
   * @param category 
   * @param parentCategory 
   */
  routeToSelectedCategory(type, category, parentCategory?) {
    if (type === 'events') {
      this.router.navigate(['/events'], { queryParams: { type: 'Events', categoryID: category.id } });
      this.snav.toggle();
      this.Accordion.map(accordian => accordian.close());
      setTimeout(() => {
        this._appService.selectedCategoryId.next({ category, parentCategory });
      }, 1000)
    } else if (type === 'news' && category.message_category_name !== "Vaillant group") {
      this.router.navigate(['/home'], { queryParams: { type: 'News', categoryID: category.id } });
      this.snav.toggle();
      this.Accordion.map(accordian => accordian.close());
      setTimeout(() => {
        this._appService.selectedCategoryId.next({ category, parentCategory });
      }, 1000)
    } else if (type === 'survey') {
      this.router.navigate(['/survey'], { queryParams: { type: 'Survey', categoryID: category.id } });
      this.snav.toggle();
      this.Accordion.map(accordian => accordian.close());
      setTimeout(() => {
        this._appService.selectedCategoryId.next({ category, parentCategory });
      }, 1000)
    }
  }
  /****
  * Route to Survey Page
  */
  routeToSurvey() {
    this.snav.toggle();
    this.Accordion.map(accordian => accordian.close());
    this.router.navigate(['/survey']);
  }
}
