import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-wie-is-wie-detail',
  templateUrl: './wie-is-wie-detail.component.html',
  styleUrls: ['./wie-is-wie-detail.component.scss']
})
export class WieIsWieDetailComponent implements OnInit {
  userId: any;
  isLoading: boolean = false;
  private _unsubscribeAll: Subject<any>;
  userData: any;
  defaultAvatar = 'assets/images/profileimg.png';
  constructor(
    private activatedRoute: ActivatedRoute,
    private _appService: AppServiceService
  ) {
    this.activatedRoute.params.subscribe(id => {
      this.userId = id.id;
      this.getUserDetails();
      this._unsubscribeAll = new Subject();
    });
  }

  ngOnInit(): void {
    this.getUserDetails();
  }

  getUserDetails() {
    const formData: any = new FormData();
    formData.append('user_id', this.userId);
    formData.append('language', localStorage.getItem('language'));
    this.isLoading = true;
    this._appService.getUserById(formData).subscribe((response: any) => {
      this.isLoading = false;
      this.userData = response.data[0];
    }, error => {
      this.isLoading = false;
    })
  }
}
