import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SurveyService } from '../survey.service';
@Component({
  selector: 'app-survey-option-radio',
  templateUrl: './survey-option-radio.component.html',
  styleUrls: ['./survey-option-radio.component.scss']
})
export class SurveyOptionRadioComponent implements OnInit {
  @Input() question;
  @Input() answer;
  @Input() isEditAccess;
  @Input() index;
  @Input() isRequired = false;
  @Input() options;
  selectedOption;
  private _unsubscribeAll: Subject<any>;
  @Output('answerValue') answerValue: EventEmitter<any> = new EventEmitter();
  language = localStorage.getItem('language');
  userId: any;
  showError: boolean = false;
  selectedValues: any;
  constructor(
    private _appService: AppServiceService,
    private router: Router,
    private _surveyService: SurveyService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (this.question.input_required === 1)
      this.isRequired = true;
    if (localStorage.getItem('userData')) {
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    }
    this._surveyService.unAttemptedQuestions.pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
      let checkAttempted = response.includes(this.question.id);
      if (checkAttempted) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    });
    const selectedOption = this.options.find(option => option.answer == 1);
    if (selectedOption) {
      this.selectedValues = selectedOption.id;
    }
  }
  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  checkSelected(event) {
    this.answerValue.emit({ questionId: this.question.id, answer: event.id });
  }

}
