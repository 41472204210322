import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input() backgroundColor;
  @Input() labels;
  @Input() percentage;
  data: any[] = [];
  public chartType: string = 'pie';

  public chartDatasets: Array<any> = [
  ];

  public chartLabels: Array<any> = this.labels;

  public chartColors: Array<any> = [
    {
      backgroundColor: this.backgroundColor,
      hoverBackgroundColor: this.backgroundColor,
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };

  constructor() { }

  ngOnInit(): void {
    this.data = this.percentage.map((percentage: any) => {
      return parseFloat(percentage);
    })
    this.chartDatasets = [{
      data: this.data,
      label: ''
    }];
    this.chartColors = [{
      backgroundColor: this.backgroundColor,
      hoverBackgroundColor: this.backgroundColor,
      borderWidth: 2,
    }];
    this.chartLabels = this.labels;
  }

  public chartClicked(e: any): void { }
  public chartHovered(e: any): void {
  }
}
