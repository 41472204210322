import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {
        const userData = localStorage.getItem('userData');
        if (localStorage.getItem('userData')) {
            return true;
        } else {
            this.router.navigate(['/login']);
        }
        // not logged in so redirect to login page with the return url
        // return;
    }
}
