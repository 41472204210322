import { Component, OnInit, Input } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  email;
  password;
  isLoginAccess: boolean;
  private _unsubscribeAll: Subject<any>;
  language;
  urlString;
  url;
  userToken;
  @Input() uToken;
  constructor(
    private _appService: AppServiceService,
    private router: Router,
    private _snackBar: MatSnackBar,
    public translate: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
    translate.use("nl");
  }

  ngOnInit(): void {
      this.language = 'nl';
      localStorage.setItem('language', this.language);
      this.urlString = window.location;
      this.url = new URL(this.urlString);
      this.userToken = this.url.searchParams.get("usertoken");
      if(this.userToken != null) {
        this.login(this.userToken);
      } else if(localStorage.getItem('userToken')) {
        // this.login(localStorage.getItem('userToken'));
        this.router.navigate(['/homescreen']);
      }
  }

  login(uToken?) {
    let token = this.makeRandomToken(163);
    const formData: any = new FormData();
    formData.append('email', this.email);
    formData.append('password', this.password);
    formData.append('device_type', 0);
    formData.append('device_token', token);
    formData.append('language', '');
    formData.append('user_token', uToken);
    this._appService.login(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
      if (response.status === 1) {
        this.isLoginAccess = true;
        let key = 'userData';
        localStorage.setItem(key, JSON.stringify(response.data));
        localStorage.setItem('language', response.data.language);
        localStorage.setItem('userToken', uToken);
        this.router.navigate(['/homescreen']);
      }
      if (response.status === 0) {
        this._snackBar.open('Invalid Login Credentials', 'Close', {
          duration: 2000
        });
      }

    })

  }

  makeRandomToken(lengthOfCode: number) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890,.";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return text;
  }

  langChange() {
    this.translate.use(this.language);
    localStorage.setItem('language', this.language);
  }
}
