import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import { MatMenuTrigger } from '@angular/material/menu';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-categorytabs',
  templateUrl: './categorytabs.component.html',
  styleUrls: ['./categorytabs.component.scss']
})
export class CategorytabsComponent implements OnInit, OnChanges {
  @Input() categories = [];
  @Input() categoryType;
  @ViewChild('cats') tabs: MatTabGroup;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  private _unsubscribeAll: Subject<any>;
  listingType;
  selectedTabIndex = 0;
  selectedCategoryId = 1;
  currentSubCategory = null;

  constructor(
    private _appService: AppServiceService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (this.categoryType === 'event_category_name') {
      this.listingType = 'Events';
    } else if (this.categoryType === 'message_category_name') {
      this.listingType = 'News';
    } else if (this.categoryType === 'survey_category_name') {
      this.listingType = 'Survey';
    }
    this._appService.selectedCategoryId.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      if (!data || !this.categories || !Array.isArray(this.categories)) {
        return;
      }
      const { category, parentCategory } = data;
      this.currentSubCategory = null;
      this.selectedCategoryId = category.id;
      if (parentCategory) {
        this.currentSubCategory = category.event_category_name || category.message_category_name;
      }
      const tabIndex = this.categories.findIndex(cat => cat.id === (parentCategory ? parentCategory.id : category.id));
      if (tabIndex !== -1) {
        this.selectedTabIndex = tabIndex;
      }
    });

  }

  ngAfterViewInit(): void {
    this.tabs._handleClick = this.interceptTabChange.bind(this);
  }

  interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    const category = this.categories.find(category => category[this.categoryType] === tab.textLabel);
    if (category.sub_category.length > 0) {
      this.trigger.openMenu();
      return false;
    }
    const selectedCategory = this.categories[idx];
    this.selectedCategoryId = selectedCategory.id;
    this.selectedTabIndex = idx;
    return MatTabGroup.prototype._handleClick.apply(this.tabs, arguments);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.categories !== changes.categories.currentValue) {
      this.categories = changes.categories.currentValue;
      this.selectedCategoryId = this.categories[0].id;
    }
  }
  subCatChange(subCat: any, tabIndex: number) {
    this.selectedTabIndex = tabIndex;
    this.currentSubCategory = subCat[this.categoryType];
    this.selectedCategoryId = subCat.id;
  }

  tabClick(ev) {
    ev.stopPropagation();
  }

}
