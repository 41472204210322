<div id="main" class="maincontent newsdetailcontent">
    <div id="primary" class="content-area one-column">
        <div id="content" class="site-content">
            <div class="contenttopsection contentspacing contentimg">
                <div class="wieiswiesection">
                    <figure>
                        <img [src]="userData?.profile_image ? userData?.profile_image : defaultAvatar" alt="">
                    </figure>
                    <h2 class="cursor-pointer" style="padding-left: 10px;padding-top:10px;color: black;">
                        {{userData?.first_name}}
                        {{userData?.last_name}}
                    </h2>
                    <p class="cursor-pointer" *ngIf="userData?.email" style="padding-left: 10px">
                        <a style="color: black;" href="mailto:{{userData?.email}}">{{userData?.email}}
                        </a>
                    </p>
                    <p class="cursor-pointer" *ngIf="!userData?.email" style="padding-left: 10px">--</p>
                    <table class="responsivetable bglightgrey tablemargin" style="margin-top: 20px;">
                        <div style="left: 50%;top: 45%;position: fixed" *ngIf="isLoading">
                            <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                        <tbody class="cursor-pointer">
                            <!-- <tr class="cursor-pointer">
                                <td>{{'name' | translate}}</td>
                                <td>{{userData?.first_name}} {{userData?.last_name}}</td>
                            </tr> -->
                            <!-- <tr class="cursor-pointer">
                                <td>{{'email' | translate}}</td>
                                <td *ngIf="userData?.email"><a href="mailto:{{userData?.email}}">{{userData?.email}}</a>
                                </td>
                                <td *ngIf="!userData?.email"><a>--</a></td>
                            </tr> -->
                            <tr class="cursor-pointer">
                                <td>
                                    <mat-icon class="icon-display">
                                        <a href="tel:{{userData?.phone}}">local_phone</a></mat-icon>
                                </td>
                                <!-- <td>{{'phone_number' | translate}}</td> -->
                                <td *ngIf="userData?.phone"><a href="tel:{{userData?.phone}}">{{userData?.phone}}</a>
                                </td>
                                <td *ngIf="!userData?.phone">--</td>
                            </tr>
                            <tr class="cursor-pointer">
                                <td>
                                    <mat-icon class="icon-display">
                                        <a
                                        href="tel:{{userData?.mobile}}">smartphone</a></mat-icon>
                                </td>
                                <td *ngIf="userData?.mobile"><a
                                        href="tel:{{userData?.mobile}}">{{userData?.mobile}}</a>
                                </td>
                                <td *ngIf="!userData?.mobile">--</td>
                            </tr>
                            <tr class="cursor-pointer">
                                <td>
                                    <mat-icon class="icon-display">
                                        supervised_user_circle</mat-icon>
                                </td>
                                <!-- <td>{{'department' | translate}}</td> supervised_user_circle -->
                                <td>{{userData?.user_department_name}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>