import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppServiceService } from '../app-service.service';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;
  public emailValidated: boolean = false;
  public emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  public passwordPattern = /^[ -~]{6,63}$/;
  public forgotPasswordForm: FormGroup;
  public passwordError: boolean = false;
  public submitted: boolean;
  public isLoading = false;
  public language = localStorage.getItem('language');
  userId: any;

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private _appService: AppServiceService,
    private translateService: TranslateService) {
      this._unsubscribeAll = new Subject();
    }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      confirmpassword: new FormControl('', [Validators.required])
    })

    // this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
    //   console.log(language);
    //   this.language = language;
    //   if (localStorage.getItem('language') !== language) {

    //   }
    // });
  }

  forgotPassword() {
    const email = this.forgotPasswordForm.controls['email'].value;
    if (!email || !this.emailPattern.test(email)) {
      this.translateService.get('please_enter_email_address').subscribe(message => {
        this._snackBar.open(message, 'Close', { duration: 2000 });
      });
      return;
    }
    this.submitted = true;

    if (!this.emailValidated) {
      this.validateEmail(email);
    }
  }

  validateEmail(email) {
    this.isLoading = true;
    const formData: any = new FormData();
    formData.append('email', email);
    formData.append('language', this.language)
    formData.append('device_type', 0)
    formData.append('is_forgot_pswd', 1)

    this._appService.checkEmail(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
      if (response.status === 1) {
        this.emailValidated = true;
        this.submitted = false;
        this.userId = response.data.id;
        this.router.navigate(['/login']);
        this.translateService.get(response.message).subscribe(message => {
          this._snackBar.open(message, 'Close', { duration: 2000 });
        });
      }
      else {
        this.emailValidated = false;
        this.router.navigate(['/login']);
        this.translateService.get('something_went_wrong').subscribe(message => {
          this._snackBar.open(message, 'Close', { duration: 2000 });
        });
      }
      this.isLoading = false;
    }, error => {
      this.translateService.get('something_went_wrong').subscribe(message => {
        this._snackBar.open(message, 'Close', { duration: 2000 });
      });
      this.isLoading = false;
    })
  }

  langChange() {
    this.translateService.use(this.language);
    localStorage.setItem('lang', this.language);
  }
}
