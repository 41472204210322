import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, window } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  userId: any;
  emailId: any;
  deviceInfo:any;
  public passwordPattern = /^[ -~]{6,63}$/;
  public resetPasswordForm: FormGroup;
  public passwordError: boolean = false;
  public submitted: boolean;
  public isLoading = false;

  constructor(
    private _appService: AppServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
  ) { 
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.params.id;
    this.emailId = this.activatedRoute.snapshot.params.email;
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    console.log(this.deviceInfo.os);

    if(this.deviceInfo.os=="iOS"){
      location.href = 'WeCare://https://wecarevillantgroup.page.link/reset-password/'+ this.emailId + '/' + this.userId;
    }
    else if(this.deviceInfo.os=="Android"){
      location.href = 'https://webadmin.wecare-vaillantgroup.com/'+ this.emailId + '/' + this.userId;
    }

    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmpassword: new FormControl('', [Validators.required])
    })
    
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
      this.language = language;
      if (localStorage.getItem('language') !== language) {

      }
    });
  }

  resetPassword() {
    const password = this.resetPasswordForm.controls['password'].value;
    if (!password || !this.passwordPattern.test(password)) {
      this.translateService.get('password_should_be_greater_than_6').subscribe(message => {
        this._snackBar.open(message, 'Close', { duration: 2000 });
      });
    }

    if (this.resetPasswordForm.valid) {
      const formData: any = new FormData();
      this.isLoading = true;
      formData.append('password', password);
      formData.append('id', this.userId);
      // formData.append('id', 2);
      formData.append('is_forgot_pswd', 1);
      formData.append('device_type', 0);
      this._appService.createPassword(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
        if (response.status === 1) {
          this.isLoading = false;
          this._snackBar.open('Password Created Successfully', 'Close', { duration: 2000 });
          this.router.navigate(['/login']);
        } else {
          this.isLoading = false;
          this._snackBar.open('Invalid', 'Close', {
            duration: 2000
          });
        }
      })
    }
  }

  langChange() {
    this.translateService.use(this.language);
    localStorage.setItem('lang', this.language);
  }
}
