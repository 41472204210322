<div id="main" class="maincontent eventdetailcontent">
    <div id="primary" class="content-area one-column">
        <div id="content" class="site-content">
            <div style="position: absolute;left: 50%;top: 20%;" *ngIf="isLoading">
                <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
            <div class="contenttopsection noborder pt0">
                <div class="detailbannerimg bgimg" *ngIf="eventDetail?.image">
                    <img [src]="eventDetail?.image" alt="eventdetailbanner" class="postimg img-responsive textdetails">
                </div>
                <div class="detailbannerimg" *ngIf="!eventDetail?.image">
                </div>
                <div class="detailcontentwrapper">
                    <div class="wrap" *ngIf="status === 1 ">
                        <div class="catwrapper">
                            <span class="caticon bgdarkyellow">
                                <img [src]="'assets/images/Vaillant-icon-events.png'" alt=""></span>
                            <ul class="postdatecat">
                                <li>{{eventDetail?.created_at}}</li>
                            </ul>
                        </div>
                        <table class="responsivetable bglightyellow">
                            <tbody>
                                <tr>
                                    <td>{{'Start Date' | translate}}</td>
                                    <td>{{eventDetail?.event_start_date}}</td>
                                </tr>
                                <tr>
                                    <td>{{'End Date' | translate}}</td>
                                    <td>{{eventDetail?.event_end_date}}</td>
                                </tr>
                                <tr>
                                    <td>{{'Start Time' | translate}}</td>
                                    <td>{{eventDetail?.event_time}}</td>
                                </tr>
                                <tr>
                                    <td>{{'Location' | translate}}</td>
                                    <td>{{eventDetail?.address}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3>{{eventDetail?.title}}</h3>
                        <div [innerHTML]="eventDetail?.description"></div>
                    </div>
                    <div class="wrap" *ngIf="status ===0">
                        {{'no_records_found' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>