import { Component, OnInit, Input, SimpleChanges, HostListener, OnChanges } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SurveyService } from '../survey.service';
@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit, OnChanges {
  @Input() type;
  @Input() listType;
  userId: any;
  isLoading: boolean = false;
  allNews: any;
  newsList = []
  pageNo: number = 1;
  pageEnd: boolean = false;
  totalPages: number;
  newsPerPage: number;
  allEvents: any;
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  surveyList: any;
  status: any;
  constructor(
    private _appService: AppServiceService,
    private _surveyService: SurveyService) {
    this._unsubscribeAll = new Subject();

  }

  ngOnInit(): void {
    if (this.listType === 'News') {
      this.getAllNews();
    } else if (this.listType === 'Events') {
      this.getAllEvents()
    } else if (this.listType === 'Survey') {
      this.getAllSurvey();
    }
    // this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
    //   this.language = language;
    //   if (localStorage.getItem('language') !== language) {
    //     localStorage.setItem('language', language);
    //     if (this.listType === 'News') {
    //       this.getAllNews();
    //     } else if (this.listType === 'Events') {
    //       this.getAllEvents()
    //     } else if (this.listType === 'Survey') {
    //       this.getAllSurvey();
    //     }
    //   }
    // })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type.currentValue !== changes.type.previousValue) {
      this.type = changes.type.currentValue;
      this.newsList = [];
      this.pageNo = 1;
      if (this.listType === 'News') {
        this.getAllNews();
      } else if (this.listType === 'Events') {
        this.getAllEvents()
      } else if (this.listType === 'Survey') {
        this.getAllSurvey();
      }
    }
  }

  getAllNews(type?) {
    this.userId = JSON.parse(localStorage.getItem('userData')).id;
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('page_no', this.pageNo);
    formData.append('type', this.type);
    this.isLoading = true;
    this._appService.getAllNews(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      this.isLoading = false;
      this.allNews = data;
      this.status = data.status;
      if (type) {
        this.newsList.push(...data.data);
      } else {
        this.newsList = data.data;
      }
      this.totalPages = data.total_count; //2
      this.newsPerPage = data.per_page; //10
    }, error => {
      this.isLoading = false;
    })
  }

  /**
     * 
     * @param event 
     * For Infinite scrolling of message
     */
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (event.target.scrollingElement.scrollTop + event.target.scrollingElement.offsetHeight >= event.target.scrollingElement.scrollHeight && this.pageNo < this.totalPages) {
      this.pageNo += 1;
      if (this.listType === 'News') {
        this.getAllNews('scroll');
      } else if (this.listType === 'Events') {
        this.getAllEvents()
      } else if (this.listType === 'Survey') {
        this.getAllSurvey();
      }
    }
  }

  getAllEvents(type?) {
    this.userId = JSON.parse(localStorage.getItem('userData')).id;
    const formData: any = new FormData();
    formData.append('page_no', '1');
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('type', this.type);
    this.isLoading = true;
    this._appService.getAllEvents(formData).subscribe((events: any) => {
      this.isLoading = false;
      this.status = events.status;
      this.totalPages = events.total_count;
      if (type) {
        this.allEvents.push(...events.data);
      } else {
        this.allEvents = events.data;
      }
    }, error => {
      this.isLoading = false;
    })
  }
  /**
  * Get Survey List
  */
  getAllSurvey(type?) {
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('page_no', this.pageNo);
    this.isLoading = true;
    this._surveyService.getSurveyList(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      this.totalPages = data.total_count;
      this.surveyList = data.data;
      this.status = data.status;
      this.isLoading = false;
      if (type) {
        this.surveyList.push(...data.data);
      } else {
        this.surveyList = data.data;
      }
    }, error => {
      this.isLoading = false;
    })
  }

}
