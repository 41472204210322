import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SurveyService } from '../survey.service';
@Component({
  selector: 'app-survey-result',
  templateUrl: './survey-result.component.html',
  styleUrls: ['./survey-result.component.scss']
})
export class SurveyResultComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  language = localStorage.getItem('language');
  userId: any;
  surveyId: any;
  isLoading: boolean = false;
  surveyDetail: any;
  status: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private _appService: AppServiceService,
    private router: Router,
    private _surveyService: SurveyService
  ) {
    this.activatedRoute.params.subscribe(id => {
      this.surveyId = id.id;
    });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (localStorage.getItem('userData')) {
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    }
    this.getSurveyResult();
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
      this.language = language;
      if (localStorage.getItem('language') !== language) {
        this.getSurveyResult();
      }
    })
  }
  /**
 * On destroy
 */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getSurveyResult() {
    if (!this.userId) return;
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('survey_id', this.surveyId);
    this.isLoading = true;
    this._surveyService.getSurveyResult(formData).subscribe((survey: any) => {
      if (survey.status !== 0) {
        this.isLoading = false;
        this.status = survey.status;
        this.surveyDetail = survey.data;
      }
    }, error => {
      this.isLoading = false;
    })
  }

}
