<!-- Survey -->
<div style="position: absolute;left: 50%;top: 20%;" *ngIf="isLoading">
    <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>
<div *ngIf="!isLoading">
    <div class="wrap" *ngIf="status === 0">
        {{'no_records_found' | translate}}
    </div>
    <div *ngIf="surveyList && surveyList.length === 0">
        {{'no_records_found' | translate}}
    </div>
    <div>
        <ul class="postlisting bordermain" *ngIf="type === 1">
            <li *ngFor="let survey of surveyList">
                <div class="wrap" routerLink="/survey-detail/{{survey.id}}">
                    <div class="postleft">
                        <figure class="postimg newsimg" *ngIf="survey?.image">
                            <img [src]="survey?.image" alt=""></figure>
                        <div class="postdetails">
                            <ul class="postdatecat" style="margin-left: 10px">
                                <li class="cursor-pointer">{{survey?.created_at}}</li>
                            </ul>
                            <h4 class="cursor-pointer" style="margin-left: 10px">
                                <a>{{survey?.title}}</a></h4>
                            <p class="cursor-pointer" style="margin-left: 10px">
                                {{survey?.short_description}}</p>
                        </div>
                    </div>
                    <div class="postright mainblue">
                        <img [src]="'assets/images/enquete.png'" class="mainicon">
                        <span class="text-enquete cursor-pointer">{{'survey' | translate}}</span>
                        <a class="arrow borderblue" routerLink="/survey-detail/{{survey.id}}">
                        </a>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="postlisting bordermain" *ngIf="type === 2">
            <li *ngFor="let survey of surveyList">
                <div class="wrap" routerLink="/survey-result/{{survey.id}}">
                    <div class="postleft">
                        <figure class="postimg newsimg" *ngIf="survey?.image">
                            <img [src]="survey?.image" alt=""></figure>
                        <div class="postdetails">
                            <ul class="postdatecat" style="margin-left: 10px">
                                <li class="cursor-pointer">{{survey?.created_at}}</li>
                            </ul>
                            <h4 class="cursor-pointer" style="margin-left: 10px">
                                <a>{{survey?.title}}</a></h4>
                            <p class="cursor-pointer" style="margin-left: 10px">
                                {{survey?.short_description}}</p>
                        </div>
                    </div>
                    <div class="postright mainblue">
                        <img [src]="'assets/images/enquete.png'" class="mainicon">
                        <span class="text-enquete cursor-pointer">{{'results' | translate}}</span>
                        <a class="arrow borderblue" routerLink="/survey-result/{{survey.id}}">
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>