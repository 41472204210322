import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from '../app/home-page/home-page.component';
import { DetailPageComponent } from '../app/detail-page/detail-page.component';
import { LoginPageComponent } from '../app/login-page/login-page.component';
import { SignupComponent } from '../app/signup/signup.component';
import { AuthGuard } from './shared/auth.guard';
import { HomescreenComponent } from './homescreen/homescreen.component';
import { EventsComponent } from './events/events.component';
import { WieIsWieComponent } from './wie-is-wie/wie-is-wie.component';
import { WieIsWieDetailComponent } from './wie-is-wie-detail/wie-is-wie-detail.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { SurveyComponent } from './survey/survey.component';
import { SurveyDetailComponent } from './survey-detail/survey-detail.component';
import { SurveyResultComponent } from './survey-result/survey-result.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:id/:email', component: ResetPasswordComponent },
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  { path: 'news-detail/:did', component: DetailPageComponent, canActivate: [AuthGuard] },
  { path: 'homescreen', component: HomescreenComponent, canActivate: [AuthGuard] },
  { path: 'events', component: EventsComponent, canActivate: [AuthGuard] },
  { path: 'events-detail/:id', component: EventDetailComponent, canActivate: [AuthGuard] },
  { path: 'wie-is-wie', component: WieIsWieComponent, canActivate: [AuthGuard] },
  { path: 'wie-is-wie-detail/:id', component: WieIsWieDetailComponent, canActivate: [AuthGuard] },
  { path: 'survey', component: SurveyComponent, canActivate: [AuthGuard] },
  { path: 'survey-detail/:id', component: SurveyDetailComponent, canActivate: [AuthGuard] },
  { path: 'survey-result/:id', component: SurveyResultComponent, canActivate: [AuthGuard] },
  { path: 'pie-chart', component: PieChartComponent, canActivate: [AuthGuard] },
  { path: 'bar-chart', component: BarChartComponent, canActivate: [AuthGuard] },
  { path: 'page-not-found', component: NotFoundComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
