<div id="wrapper">
    <div id="main" class="maincontent enquetedetailcontent enqueteformcontent">
        <div id="primary" class="content-area one-column" *ngIf="!isLoading">
            <div id="content" class="site-content">
                <div style="position: absolute;left: 50%;top: 50%;" *ngIf="isLoading">
                    <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
                    </mat-progress-spinner>
                </div>
                <div class="contenttopsection pt0 noborder" *ngIf="!isLoading">
                    <div class="detailbannerimg bgimg" *ngIf="surveyDetail?.image">
                        <img [src]="surveyDetail?.image" alt="" class="postimg img-responsive textdetails">
                    </div>
                    <div class="detailbannerimg" *ngIf="!surveyDetail?.image">
                    </div>
                    <div class="wrap" *ngIf="status === 0">
                        {{'no_records_found' | translate}}
                    </div>
                    <div class="detailcontentwrapper">
                        <div class="wrap">
                            <div class="catwrapper">
                                <span class="caticon bgdarkblue">
                                    <img [src]="'assets/images/newsicon.png'" alt="">
                                </span>
                                <ul class="postdatecat">
                                    <li>{{surveyDetail?.survey_date}} I ENQUêTES</li>
                                </ul>
                            </div>
                            <h3>
                                {{surveyDetail?.title}}
                            </h3>
                            <p>
                                {{surveyDetail?.description ? surveyDetail?.description : surveyDetail?.short_description}}
                            </p>
                            <p style="margin:0px !important;color:#005382;">{{surveyDetail?.people_filled_survey}}%
                            </p>
                            <div style="display: flex; justify-content: space-between">
                                <p style="color: #005382;margin:0px !important">People filled survey</p>
                                <p style="color:#005382;margin:0px !important">
                                    {{surveyDetail?.survey_fill_by_users}}/{{surveyDetail?.total_survey_user}}</p>
                            </div>
                            <mat-progress-bar mode="determinate" value="{{surveyDetail?.people_filled_survey}}">
                            </mat-progress-bar>
                            <div *ngFor="let question of surveyDetail?.form_data;let i= index">
                                <ul class="questionsanswer">
                                    <li class="question" style="margin-top: 20px;">
                                        <strong>Ques {{i+1}}.{{question?.label}}
                                        </strong>
                                    </li>
                                    <img [src]="question?.image" *ngIf="question?.image" alt="">
                                </ul>
                                <div *ngIf="question?.input_type === 1 || question?.input_type === 7"
                                    style="margin-top:10px">
                                    <p style="margin:0px !important;color:#005382;">{{question?.people_filled_per}}%
                                    </p>
                                    <div style="display: flex; justify-content: space-between">
                                        <p style="color: #005382;margin:0px !important">Total amount of times to provide
                                            certain answer</p>
                                        <p style="color:#005382;margin:0px !important">
                                            {{question?.people_filled}}/{{surveyDetail?.survey_fill_by_users}}</p>
                                    </div>
                                    <mat-progress-bar mode="determinate" value="{{question?.people_filled_per}}">
                                    </mat-progress-bar>
                                </div>
                                <div class="cntboxwrapper" *ngIf="question?.input_type === 1" style="margin-top:10px">
                                    <ul>
                                        <li *ngFor="let answer of question?.answers">
                                            {{answer}}
                                        </li>
                                    </ul>
                                </div>
                                <div *ngIf="question?.input_type === 5">
                                    <app-bar-chart [backgroundColor]="question?.colors" [labels]="question?.answers"
                                        [percentage]="question?.percentage"></app-bar-chart>
                                </div>
                                <div *ngIf="question?.input_type === 7">
                                    <app-pie-chart [backgroundColor]="question?.colors" [labels]="question?.answers"
                                        [percentage]="question?.percentage">
                                    </app-pie-chart>
                                </div>
                            </div>
                            <div *ngFor="let section of surveyDetail?.subtitle_data">
                                <h5 class="subtitle">Subtitle - {{section?.subtitle}}</h5>
                                <div *ngFor="let question of section?.form_data;let i= index">
                                    <ul class="questionsanswer">
                                        <li class="question" style="margin-top: 20px;">
                                            <strong>Ques {{i+1}}.{{question?.label}}
                                            </strong>
                                        </li>
                                        <img [src]="question?.image" *ngIf="question?.image" alt="">
                                    </ul>
                                    <div *ngIf="question?.input_type === 1 || question?.input_type === 7"
                                        style="margin-top:10px">
                                        <p style="margin:0px !important;color:#005382;">{{question?.people_filled_per}}%
                                        </p>
                                        <div style="display: flex; justify-content: space-between">
                                            <p style="color: #005382;margin:0px !important">Total amount of times to
                                                provide
                                                certain answer</p>
                                            <p style="color:#005382;margin:0px !important">
                                                {{question?.people_filled}}/{{surveyDetail?.survey_fill_by_users}}</p>
                                        </div>
                                        <mat-progress-bar mode="determinate" value="{{question?.people_filled_per}}">
                                        </mat-progress-bar>
                                    </div>
                                    <div class="cntboxwrapper" *ngIf="question?.input_type === 1"
                                        style="margin-top:10px">
                                        <ul>
                                            <li *ngFor="let answer of question?.answers">
                                                {{answer}}
                                            </li>
                                        </ul>
                                    </div>
                                    <div *ngIf="question?.input_type === 5">
                                        <app-bar-chart [backgroundColor]="question?.colors" [labels]="question?.answers"
                                            [percentage]="question?.percentage">
                                        </app-bar-chart>
                                    </div>
                                    <div *ngIf="question?.input_type === 7">
                                        <app-pie-chart [backgroundColor]="question?.colors" [labels]="question?.answers"
                                            [percentage]="question?.percentage">
                                        </app-pie-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>