import { Component, OnInit, HostListener } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  userId: any;
  isLoading: boolean = false;
  allEvents: any;
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  selectedTabIndex = 0
  status: any;
  eventCategory: any;
  categoryId: any;
  pageNo: number = 1;
  totalPages: number;
  scrollDistance = 1;
  scrollUpDistance = 2;
  eventData: any[] = [];
  noRecordsFound: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private _appService: AppServiceService
  ) {
    this.route
      .queryParams
      .subscribe(params => {
        if (params.categoryID) {
          this.categoryId = params.categoryID;
          this.getAllEvents();
        }
      });
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getAllEvents();
    this.getEventCategory();
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
      this.language = language;
      if (localStorage.getItem('language') !== language) {
        //this.getAllEvents();
      }
    })
  }
  /**
 * On destroy
 */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  getAllEvents(type?) {
    this.userId = JSON.parse(localStorage.getItem('userData')).id;
    const formData: any = new FormData();
    formData.append('page_no', this.pageNo);
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    if (this.categoryId)
      formData.append('type', this.categoryId);
    this.isLoading = true;
    this._appService.getAllEvents(formData).subscribe((events: any) => {
      if(events) {
        if (events.status === 0) {
          this.noRecordsFound = true;
          this.isLoading = false;
        } else {
          this.status = events.status;
          this.totalPages = events.total_count;
          this.isLoading = false;
          if (type) {
            this.allEvents.push(...events.data);
          } else {
            this.allEvents = events.data;
          }

          this.allEvents = this.allEvents.map((data: any) => {
            let iconType = '';
            let backgroundcolor = '';
            let route = ''
            switch (data.type) {
              case 1:
                iconType = 'assets/images/events-icon.png';
                backgroundcolor = '#E7B26C';
                route = 'event-detail';
                break;
              case 2: // Personal
                iconType = 'assets/images/events-icon.png';
                backgroundcolor = '#EDC691';
                route = 'event-detail';
                break;
              default:
                break;
            }
            return { ...data, iconType, backgroundcolor, route };
          });
          this.isLoading = false;
          this.status = events.status;
          this.totalPages = events.total_count;
        }
      }
    }, error => {
      this.isLoading = false;
    })
  }
  getEventCategory() {
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    this._appService.getEventCategory(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((event: any) => {
      this.eventCategory = event.eventCategory
    }, error => {
    })
  }

  /**
     * 
     * @param event 
     * For Infinite scrolling of message
     */
   @HostListener('window:scroll', ['$event']) // for window scroll events
   onScroll(event) {
     if (event.target.scrollingElement.scrollTop + event.target.scrollingElement.offsetHeight >= event.target.scrollingElement.scrollHeight && this.pageNo < this.totalPages) {
       this.pageNo += 1;
       this.getAllEvents('scroll');
     }
   }
}
