<div class="notfound">
    <div>
        <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
    </div>
    <div>
        <h1>Error 404</h1>
    </div>
    <div>
        <p>Page not found</p>
    </div>
</div>