import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SurveyService } from '../survey.service';
@Component({
  selector: 'app-survey-option-description',
  templateUrl: './survey-option-description.component.html',
  styleUrls: ['./survey-option-description.component.scss']
})
export class SurveyOptionDescriptionComponent implements OnInit {
  @Input() question;
  @Input() answer;
  @Input() isEditAccess;
  @Input() isRequired;
  @Input() index;
  inputText;
  @Output('answerValue') answerValue: EventEmitter<any> = new EventEmitter();
  private _unsubscribeAll: Subject<any>;
  language = localStorage.getItem('language');
  userId: any;
  showError: boolean = false;
  constructor(
    private _appService: AppServiceService,
    private _surveyService: SurveyService,
    private router: Router
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    if (localStorage.getItem('userData')) {
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    }
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
      this.language = language;
      if (localStorage.getItem('language') !== language) {
      }
    })
    if (this.answer) {
      this.inputText = this.answer;
    }
    this._surveyService.unAttemptedQuestions.pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
      let checkAttempted = response.includes(this.question.id);
      if (checkAttempted) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    })
  }
  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateData() {
    this.answerValue.emit({ questionId: this.question.id, answer: this.inputText });
  }

}
