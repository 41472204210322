<header id="header" [ngStyle]="{'background-color' :(backgroundcolor ? backgroundcolor : '')}">
    <div class="wrap headerwrap">
        <div class="headerleft">
            <a (click)="toggleSideDrawer()" id="menu" class="menulines-button">
                <span
                    [ngClass]="{'menulines-blue' : backgroundcolor === 'white', 'menulines' : backgroundcolor !== 'white'}"></span>
            </a>
            <a routerLink="/homescreen" id="logo" title="Wecare">
                <img src="assets/images/logo-wecare-new.png" alt="logo">
            </a>
        </div>
        <div class="header-center">
            <span [class]="headerIcon"
                [ngStyle]="{'color' :(fontColor ? fontColor : '')}">{{headerTitle | translate}}</span>
        </div>
        <div class="header-right" *ngIf="backgroundcolor !== 'white'">
            <a class="homeicon" (click)="toggleSideDrawer('close')" routerLink="/homescreen"></a>
        </div>
    </div>
</header>