<div style="position: absolute;left: 50%;top: 20%;" *ngIf="isLoading">
    <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>
<!-- News -->
<div *ngIf="!isLoading">
    <div class="wrap" *ngIf="status === 0 && listType === 'News'">
        {{'no_records_found' | translate}}
    </div>
    <div class="wrap" *ngIf="newsList?.length === 0 && listType === 'News'">
        {{'no_records_found' | translate}}
    </div>
    <div *ngIf="listType === 'News'">
        <div class="wrap mainpost" *ngFor="let data of newsList" routerLink="/news-detail/{{data.id}}">
            <div class="postleft" *ngIf="data.length !== 0">
                <figure class="postimg mainimg" *ngIf="data.image">
                    <img src="{{data?.image}}" alt="newsimg01">
                </figure>
                <figure class="postimg" *ngIf="!data.image && data.url">
                    <img [src]="data.youtube_thumbnail" alt="newsimg01">
                </figure>
                <div class="postdetails postinformation">
                    <ul class="postdatecat">
                        <li>{{data?.created_at | uppercase}}</li>
                        <li>{{data?.category}}</li>
                    </ul>
                    <h4>
                        <a>{{data?.title}}</a>
                    </h4>
                    <p>
                        {{data?.short_description}}
                    </p>
                </div>
            </div>
            <div class="postright"
                [ngStyle]="{'background-color': (data?.sub_category_name === 'Taking Care') ? '#abc32b' : '#DEE7B0'}">
                <span class="catmsgicon">{{data?.sub_category_name}}</span>
                <a routerLink="/news-detail/{{data.id}}" class="arrow"></a>
            </div>
        </div>
    </div>
</div>
<!-- Events -->
<div *ngIf="!isLoading">
    <div class="wrap" *ngIf="allEvents?.length === 0 && listType === 'Events'">
        {{'no_records_found' | translate}}
    </div>
    <div *ngIf="listType === 'Events'">
        <div class="wrap" *ngIf="status === 0">
            {{'no_records_found' | translate}}
        </div>
        <div class="wrap mainpost" *ngFor="let data of allEvents" routerLink="/events-detail/{{data.id}}">
            <div class="postleft" *ngIf="data.length !== 0">
                <figure class="postimg mainimg" *ngIf="data.image">
                    <img [src]="data?.image" alt="newsimg01">
                </figure>
                <p class="cursor-pointer" [ngStyle]="{'margin-left': (data.image !== '') ? '153px' : '10px'}"
                    style="margin-bottom: 10px;">
                    {{data?.created_at | uppercase}}</p>

                <div class="postdetails postinformation">
                    <ul class="postdatecat">
                        <li class="cursor-pointer">{{data?.address}}</li>
                        <li class="cursor-pointer">{{data?.event_start_date}}</li>
                        <li class="cursor-pointer">{{data?.event_end_date}}</li>
                    </ul>
                    <h4>
                        <a class="cursor-pointer">{{data?.title}}</a>
                    </h4>
                    <p class="cursor-pointer">
                        {{data?.short_description}}
                    </p>
                </div>
            </div>
            <div class="postright"
                [ngStyle]="{'background-color': (data?.sub_category_name === 'Client') ? '#E6B16B' : '#EEC692'}">
                <span class="catmsgicon">{{data?.sub_category_name}}</span>
                <a routerLink="/events-detail/{{data.id}}" class="arrow"></a>
            </div>
        </div>
    </div>
</div>