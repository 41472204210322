import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  eventId: any;
  userId: any;
  isLoading: boolean = false;
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  eventDetail: any;
  status: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private _appService: AppServiceService,
  ) {
    this._unsubscribeAll = new Subject();
    this.activatedRoute.params.subscribe(id => {
      this.eventId = id.id;
    });
  }

  ngOnInit(): void {
    this.getEventDetail();
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
      this.language = language;
      if (localStorage.getItem('language') !== language) {
        this.getEventDetail();
      }
    })
  }

  getEventDetail() {
    this.userId = JSON.parse(localStorage.getItem('userData')).id;
    const formData: any = new FormData();
    formData.append('user_id', this.userId);
    formData.append('event_id', this.eventId);
    formData.append('language', this.language);
    this.isLoading = true;
    this._appService.getEventDetail(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((eventDetail: any) => {
      this.isLoading = false;
      this.status = eventDetail.status;
      if (eventDetail.data)
        this.eventDetail = eventDetail.data[0];
    }, error => {
      this.isLoading = false;
    })
    this._appService.eventReadById(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    })
  }

}
