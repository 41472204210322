<div>
  <h5 class="cursor-pointer" *ngIf="!isRequired">{{index}}.{{question.input_label}}</h5>
  <h5 class="cursor-pointer" *ngIf="isRequired" style="display: contents;">{{index}}.{{question.input_label}}</h5>
  <span *ngIf="isRequired" style="color: red;margin-left: 5px;">*</span>
</div>
<div>
  <img [src]="question?.image" height="200" width="200" *ngIf="question?.image">
</div>
<div>
  <mat-form-field *ngIf="isEditAccess && !isRequired" class="example-full-width">
    <mat-label class="cursor-pointer">Answer</mat-label>
    <input matInput [(ngModel)]="inputText" (blur)="updateData()">
  </mat-form-field>
  <mat-form-field *ngIf="isEditAccess && isRequired" class="example-full-width">
    <mat-label class="cursor-pointer">Answer</mat-label>
    <input matInput [(ngModel)]="inputText" (blur)="updateData()" required>
  </mat-form-field>
  <mat-error *ngIf="showError">
    This question is required
  </mat-error>
  <p *ngIf="!isEditAccess">Ans. {{answer}}</p>
</div>
