import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { SignupComponent } from './signup/signup.component';
import { HeaderComponent } from './common/header/header.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './shared/auth.guard';
import { HomescreenComponent } from './homescreen/homescreen.component';
import { SideMenuComponent } from './common/side-menu/side-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../app/material.module';
import { SafePipe } from './safe.pipe';
import { WieIsWieComponent } from './wie-is-wie/wie-is-wie.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { EventsComponent } from './events/events.component';
import { DialogComponent } from './common/dialog/dialog.component';
import { WieIsWieDetailComponent } from './wie-is-wie-detail/wie-is-wie-detail.component';
import { NewsListComponent } from './news-list/news-list.component';
import { CategorytabsComponent } from './categorytabs/categorytabs.component';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SurveyComponent } from './survey/survey.component';
import { SurveyDetailComponent } from './survey-detail/survey-detail.component';
import { SurveyOptionDescriptionComponent } from './survey-option-description/survey-option-description.component';
import { SurveyOptionMultipleComponent } from './survey-option-multiple/survey-option-multiple.component';
import { SurveyOptionRadioComponent } from './survey-option-radio/survey-option-radio.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SurveyResultComponent } from './survey-result/survey-result.component';
import { SurveyListComponent } from './survey-list/survey-list.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChartsModule, WavesModule } from 'angular-bootstrap-md';
import { NotFoundComponent } from './not-found/not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/languages/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    ChartsModule,
    WavesModule,
    InfiniteScrollModule,
    MDBBootstrapModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [
    AppComponent,
    HomePageComponent,
    DetailPageComponent,
    LoginPageComponent,
    SignupComponent,
    HeaderComponent,
    HomescreenComponent,
    SideMenuComponent,
    SafePipe,
    WieIsWieComponent,
    EventDetailComponent,
    EventsComponent,
    WieIsWieDetailComponent,
    NewsListComponent,
    DialogComponent,
    CategorytabsComponent,
    SurveyComponent,
    SurveyDetailComponent,
    SurveyOptionDescriptionComponent,
    SurveyOptionMultipleComponent,
    SurveyOptionRadioComponent,
    SurveyResultComponent,
    SurveyListComponent,
    PieChartComponent,
    BarChartComponent,
    NotFoundComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  exports: [DialogComponent],
  entryComponents: [DialogComponent],
  providers: [AuthGuard,],
  bootstrap: [AppComponent],
})
export class AppModule { }
