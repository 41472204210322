<div id="main" #maincontent class="maincontent newsdetailcontent">
    <div id="primary" class="content-area one-column">
        <div id="content" class="site-content">
            <div style="left: 50%;top: 45%;position: fixed" *ngIf="isLoading">
                <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
            <div class="contenttopsection detailspage">
                <div class="wrap">
                    <div class="detailbannerimg bgimg mainwatch" *ngIf="currentNews?.image || currentNews?.url">
                        <img *ngIf="currentNews?.media_type == 0 && currentNews?.image && !currentNews?.url" class="postimg img-responsive textdetails" [src]="currentNews?.image" alt="newsdetailbannerimg">
                        <a href="{{ currentNews?.image }}" *ngIf="currentNews?.media_type == 1 && currentNews?.image && !currentNews?.url" style="color: #abc32b;"><i class="fa fa-file-pdf-o" style="font-size:100px;color:red;"></i></a>
                        <iframe width="100%" height="470" style="margin: 35px 0;"
                            *ngIf="!currentNews?.image && currentNews?.url" [src]="currentNews?.url | safe" frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
                        </iframe>
                    </div>
                    <div class="detailbannerimg mainwatch" *ngIf="currentNews?.pdf">
                        <a href="{{ currentNews?.pdf }}"><i class="fa fa-file-pdf-o" style="font-size:100px;color:red;"></i></a>
                        <span> {{ currentNews?.pdf_name }}</span>
                    </div>
                    <!--/.bannerimg-->
                </div>
                <div class="detailcontentwrapper maindetailswrap">
                    <div class="wrap" *ngIf="status === 1">
                        <div class="catwrapper maincatwrapper">
                            <span class="caticon bggreen">
                                <img [src]="'assets/images/newsicon.png'" alt="newsicon"></span>
                            <ul class="postdatecat">
                                <li>{{currentNews?.created_at}}</li>
                                <li>{{currentNews?.category}}
                                </li>
                            </ul>
                        </div>
                        <h3>{{currentNews?.title}}</h3>
                        <div [innerHTML]="currentNews?.description"></div>
                    </div>
                    <div class="wrap" *ngIf="status === 0">
                        <p>{{'no_records_found' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>