import { Component, OnInit, HostListener } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-wie-is-wie',
  templateUrl: './wie-is-wie.component.html',
  styleUrls: ['./wie-is-wie.component.scss']
})
export class WieIsWieComponent implements OnInit {
  userId: any;
  isLoading: boolean = false;
  users = [];
  searchText;
  defaultAvatar = 'assets/images/profileimg.png';
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  pageNo: number = 1;
  totalPages: number;
  status: any;
  constructor(
    private _appService: AppServiceService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getAllUsers();
    window.onscroll = () => {
    }
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
      this.language = language;
      if (localStorage.getItem('language') !== language) {
        this.getAllUsers();
      }
    })
  }

  getAllUsers(type?) {
    this.userId = JSON.parse(localStorage.getItem('userData')).id;
    const formData: any = new FormData();
    formData.append('page_no', this.pageNo);
    if (this.searchText) {
      formData.append('search', this.searchText);
    } else {
      formData.append('search', '');
    }
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    this.isLoading = true;
    this._appService.getAllUsers(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      this.status = data.status;
      this.totalPages = data.total_count;
      if (type) {
        this.users.push(...data.data);
      } else {
        this.users = data.data;
      }
      this.isLoading = false;
      if (this.searchText) {
        this.pageNo = 1;
        this.users = data.data;
      }
    }, error => {
      this.isLoading = false;
    })
  }
  /**
     * 
     * @param event 
     * For Infinite scrolling of message
     */
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (event.target.scrollingElement.scrollTop + event.target.scrollingElement.offsetHeight >= event.target.scrollingElement.scrollHeight && this.pageNo < this.totalPages && !this.isLoading) {
      this.pageNo += 1;
      this.getAllUsers('scroll');
    }
  }
}
