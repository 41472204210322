import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppServiceService } from '../app-service.service';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  public emailValidated: boolean = false;
  public emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  public passwordPattern = /^[ -~]{6,63}$/;
  public signupForm: FormGroup;
  public passwordError: boolean = false;
  public submitted: boolean;
  public isLoading = false;
  userId: any;
  public language = localStorage.getItem('language');
  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private _appService: AppServiceService,
    private translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.signupForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      confirmpassword: new FormControl('', [Validators.required])
    })
    this.signupForm.get('confirmpassword').valueChanges.subscribe(
      confirmPass => {
        if (confirmPass == this.signupForm.controls['password'].value) {
          this.passwordError = false;
        }
        else {
          this.passwordError = true;
        }
      }
    );
    this.signupForm.get('email').valueChanges.subscribe(
      email => {
        if (email.valid)
          this.validateEmail(email);
      }
    );
    this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
      this.language = language;
      if (localStorage.getItem('language') !== language) {

      }
    });
  }

  validateInput() {
    if (this.signupForm.controls['email'].invalid && (this.signupForm.controls['email'].touched))
      return true;
  }


  signup() {
    const email = this.signupForm.controls['email'].value;
    if (!email || !this.emailPattern.test(email)) {
      this.translateService.get('please_enter_email_address').subscribe(message => {
        this._snackBar.open(message, 'Close', { duration: 2000 });
      });
      return;
    }
    this.submitted = true;
    if (!this.emailValidated) {
      this.validateEmail(email);
    } else {
      this.submitSignup();
    }
  }

  submitSignup() {
    const password = this.signupForm.controls['password'].value;
    if (!password || !this.passwordPattern.test(password)) {
      this.translateService.get('password_should_be_greater_than_6').subscribe(message => {
        this._snackBar.open(message, 'Close', { duration: 2000 });
      });
    }
    if (this.signupForm.valid) {
      const formData: any = new FormData();
      this.isLoading = true;
      formData.append('password', this.signupForm.controls['password'].value);
      formData.append('id', this.userId);
      this._appService.createPassword(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
        if (response.status === 1) {
          this.isLoading = false;
          this._snackBar.open('Password Created Successfully', 'Close', { duration: 2000 });
          this.login();
        } else {
          this.isLoading = false;
          this._snackBar.open('Invalid', 'Close', {
            duration: 2000
          });
        }
      })
    }
  }
  /**
* On destroy
*/
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  validateEmail(email) {
    this.isLoading = true;
    const formData: any = new FormData();
    formData.append('email', email);
    formData.append('language', this.language)
    this._appService.checkEmail(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
      if (response.status === 1) {
        this.emailValidated = true;
        this.submitted = false;
        this.userId = response.data.id;
      }
      else {
        this.emailValidated = false;
        this.translateService.get('something_went_wrong').subscribe(message => {
          this._snackBar.open(message, 'Close', { duration: 2000 });
        });
        this.router.navigate(['/login']);
      }
      this.isLoading = false;
    }, error => {
      this.translateService.get('something_went_wrong').subscribe(message => {
        this._snackBar.open(message, 'Close', { duration: 2000 });
      });
      this.isLoading = false;
    })
  }
  login() {
    const formData: any = new FormData();
    formData.append('email', this.signupForm.value.email);
    formData.append('password', this.signupForm.value.password);
    formData.append('device_type', '');
    formData.append('device_token', '');
    formData.append('language', this.language);
    this._appService.login(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
      if (response.status === 1) {
        let key = 'userData';
        localStorage.setItem(key, JSON.stringify(response.data));
        localStorage.setItem('language', 'nl');
        this.router.navigate(['/homescreen']);
      }
      if (response.status === 0) {
        this._snackBar.open('Invalid Login Credentials', 'Close', {
          duration: 2000
        });
      }

    })

  }

  langChange() {
    this.translateService.use(this.language);
    localStorage.setItem('lang', this.language);
  }

}
