<div style="padding-bottom:191px">
  <div id="wrapper">
    <div id="main" class="maincontent newsdetailcontent">
      <div id="primary" class="content-area one-column">
        <div id="content" class="site-content">
          <div class="contenttopsection">
            <div style="position: absolute;left: 50%;top: 50%;" *ngIf="isLoading">
              <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
              </mat-progress-spinner>
            </div>
            <div style="position: absolute;left: 50%;top: 50%;" *ngIf="noRecordsFound">
              <h2> {{'no_records_found' | translate}}</h2>
            </div>
            <div class="bggray">
              <ul class="postlisting listinggray">
                <li *ngFor="let data of dashboardData">
                  <div [ngStyle]="{ 'background-color': data.is_read == 1 ? '#E5ECF3' : '#BECF59'}" class="pd-10">
                    <div class="wrap" routerLink="/{{data.route}}/{{data.id}}">
                      <div class="postleft">
                        <figure class="postimg newsimg" *ngIf="data?.image">
                          <img [src]="data?.image" alt="">
                        </figure>
                        <div class="postdetails">
                          <ul class="postdatecat" style="margin-left: 10px">
                            <li class="maintextnews cursor-pointer" [ngStyle]="{ 'color': data.is_read == 0 ? '#038544' : '#000'}">{{data?.created_at}}</li>
                          </ul>
                          <h4 class="cursor-pointer" style="margin-left: 10px">
                            <a [ngStyle]="{ 'color': data.is_read == 0 ? '#038544' : '#000'}">{{data?.title}}</a></h4>
                          <p class="cursor-pointer" style="margin-left: 10px;" [ngStyle]="{ 'color': data.is_read == 0 ? '#038544' : '#000'}">
                            {{data?.short_description}}
                          </p>
                        </div>
                      </div>
                      <div class="postright" [ngStyle]="{'background-color' :data.backgroundcolor}">
                        <span class="catmsgicon">
                          <img [src]="data.iconType" class="mainicon">
                        </span>
                        <a class="arrow" routerLink="/{{data.route}}/{{data.id}}"></a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="homebottomnavwrapper">
    <div class="column">
      <ul class="maincolumn">
        <li>
          <a class="bggreen bgwidth" [routerLink]="['/home']" [queryParams]="{ type : 'News'}">
            <img [src]="'assets/images/news-white.png'" alt="newsicon">
            <span class="textmain cursor-pointer">{{'news' | translate}}</span>
            <em class="cursor-pointer" *ngIf="dashboardList?.message_unread_count !== 0"
              [ngStyle]="{'background-color': (dashboardList?.message_unread_count !== 0) ? 'red' : '#abc32b'}">{{dashboardList?.message_unread_count}}</em>
          </a>
        </li>
        <li>
          <a class="bgdarkyellow yellowwidth" [routerLink]="['/events']" [queryParams]="{ type : 'Events'}">
            <img [src]="'assets/images/Vaillant-icon-events.png'" alt="newsicon">
            <span class="cursor-pointer">{{'events' | translate}}</span>
            <em class="cursor-pointer" *ngIf="dashboardList?.event_unread_count !== 0"
              [ngStyle]="{'background-color': (dashboardList?.event_unread_count !== 0) ? 'red' : '#e09d49'}">{{dashboardList?.event_unread_count}}</em>
          </a>
        </li>
        <li>
          <a class="bgdarkblue bluewidth" [routerLink]="['/survey']" [queryParams]="{ type : 'Survey'}">
            <img [src]="'assets/images/enqueteblue.png'" alt="newsicon" class="iconwidth">
            <span class="cursor-pointer">{{'survey' | translate}}</span>
            <em class="cursor-pointer" *ngIf="dashboardList?.survey_unread_count !== 0"
              [ngStyle]="{'background-color': (dashboardList?.survey_unread_count !== 0) ? 'red' : '#005382'}">{{dashboardList?.survey_unread_count}}</em>
          </a>
        </li>
        <li>
          <a class="bggrey graywidth" routerLink="/wie-is-wie">
            <img [src]="'assets/images/Vaillant.png'" alt="newsicon">
            <span class="cursor-pointer">{{'who_is_who' | translate}}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
