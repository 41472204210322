<div id="wrapper">
  <div id="main" class="maincontent enquetedetailcontent">
    <div id="primary" class="content-area one-column">
      <div id="content" class="site-content">
        <div class="contenttopsection pt0" style="border-bottom: 0px !important;">
          <div class="detailbannerimg bgimg" *ngIf="surveyDetail?.image">
            <img [src]="surveyDetail?.image" alt="" class="postimg img-responsive textdetails">
          </div>
          <div class="detailbannerimg " *ngIf="!surveyDetail?.image">
          </div>
          <div *ngIf="status === 0" style="display: flex; justify-content: center; align-items: center">
            {{'no_records_found' | translate}}
          </div>
          <div style="left: 50%;top: 50%;position: fixed" *ngIf="isLoading">
            <mat-progress-spinner diameter="50" color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </div>
          <div class="detailcontentwrapper" *ngIf="status ===1">
            <div class="wrap">
              <div class="catwrapper">
                <span class="caticon bgdarkblue">
                  <img [src]="'assets/images/newsicon.png'" alt="">
                </span>
                <ul class="postdatecat">
                  <li class="cursor-pointer">
                    {{surveyDetail?.created_at ? surveyDetail?.created_at : surveyDetail?.filled_survey_date }}
                  </li>
                </ul>
              </div>
              <h3 class="cursor-pointer">
                {{surveyDetail?.title ? surveyDetail?.title : surveyDetail?.data?.title}}</h3>
              <p class="cursor-pointer">
                {{surveyDetail?.description ? surveyDetail?.description : surveyDetail?.data?.description}}
              </p>
              <ul class="questionsanswer">
                <li *ngFor="let question of surveyDetail?.form_data; let i = index">
                  <div *ngIf="surveyDetail?.is_fill === 0">
                    <app-survey-option-description *ngIf="question?.input_type === 1" [question]="question"
                      [isEditAccess]="true" (answerValue)="getAnswers($event)" [index]="i+1"
                      [isRequired]="question?.input_required">
                    </app-survey-option-description>
                    <app-survey-option-multiple *ngIf="question?.input_type === 5" [question]="question"
                      [isEditAccess]="true" [index]="i+1" (answerValue)="getAnswers($event)"
                      [options]="question?.options" [isRequired]="question?.input_required"
                      [maxOptionSelect]="question?.min_option_select">
                    </app-survey-option-multiple>
                    <app-survey-option-radio *ngIf="question?.input_type === 7" [question]="question"
                      [isEditAccess]="true" [index]="i+1" (answerValue)="getAnswers($event)"
                      [options]="question?.options">
                    </app-survey-option-radio>
                  </div>
                </li>
              </ul>
              <div *ngIf="!surveyDetail?.is_fill">
                <ul *ngFor="let question of surveyDetail?.data?.form_data; let i = index">
                  <app-survey-option-description *ngIf="question?.input_type === 1" [question]="question"
                    [isEditAccess]="false" [index]="i+1" [answer]="question?.answer">
                  </app-survey-option-description>
                  <app-survey-option-multiple *ngIf="question?.input_type === 5" [question]="question"
                    [isEditAccess]="false" [index]="i+1" [options]="question?.options" [answer]="question?.answer">
                  </app-survey-option-multiple>
                  <app-survey-option-radio *ngIf="question?.input_type === 7" [question]="question"
                    [isEditAccess]="false" [index]="i+1" [options]="question?.options" [answer]="question?.answer">
                  </app-survey-option-radio>
                </ul>
              </div>
              <ul *ngFor="let survey of surveyDetail?.subtitle">
                <h4>{{survey?.subtitle}}</h4>
                <div *ngFor="let question of survey?.question; let i = index">
                  <app-survey-option-description *ngIf="question?.input_type === 1" [question]="question"
                    [isEditAccess]="true" (answerValue)="getAnswers($event, 'subtitle')" [index]="i+1"
                    [isRequired]="question?.input_required">
                  </app-survey-option-description>
                  <app-survey-option-multiple *ngIf="question?.input_type === 5" [question]="question"
                    [isEditAccess]="true" [index]="i+1" (answerValue)="getAnswers($event, 'subtitle')"
                    [options]="question?.options" [isRequired]="question?.input_required"
                    [maxOptionSelect]="question?.min_option_select">
                  </app-survey-option-multiple>
                  <app-survey-option-radio *ngIf="question?.input_type === 7" [question]="question"
                    [isEditAccess]="true" [index]="i+1" (answerValue)="getAnswers($event, 'subtitle')"
                    [options]="question?.options">
                  </app-survey-option-radio>
                </div>
              </ul>
              <div *ngIf="!surveyDetail?.is_fill">
                <ul *ngFor="let subtitle of surveyDetail?.data?.subtitle">
                  <h4>{{subtitle?.subtitle}}</h4>
                  <li *ngFor="let question of subtitle?.question;let i = index">
                    <app-survey-option-description *ngIf="question?.input_type === 1" [question]="question"
                      [isEditAccess]="false" [index]="i+1" [answer]="question?.answer">
                    </app-survey-option-description>
                    <app-survey-option-multiple *ngIf="question?.input_type === 5" [question]="question"
                      [isEditAccess]="false" [index]="i+1" [options]="question?.options" [answer]="question?.answer">
                    </app-survey-option-multiple>
                    <app-survey-option-radio *ngIf="question?.input_type === 7" [question]="question"
                      [isEditAccess]="false" [index]="i+1" [options]="question?.options" [answer]="question?.answer">
                    </app-survey-option-radio>
                  </li>
                </ul>
              </div>
              <button *ngIf="status === 1 && surveyDetail?.is_fill === 0" mat-raised-button class="mat-primary mr-16"
                (click)="submit()">Submit</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
