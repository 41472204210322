import { Component, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('snav') snav;
  public backgroundcolor = '';
  constructor(public router: Router,
    private translate: TranslateService,) {
    let templan = localStorage.getItem("language");
    if (templan == null || templan == "") {
      translate.use("nl");
      localStorage.setItem("language", "nl");
    } else {
      translate.use(templan);
    }
  }
  showHeader = true;
  ngOnInit(): void {
    this.handleColorChange(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/' || event.url === '/login' || event.url === '/signup' || event.url === '/forgot-password' || event.url.includes('/reset-password') || event.url.includes('/login')) {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }
        this.handleColorChange(event.url);
      }
    });
  }
  handleColorChange(url) {
    const path = url.split("?")[0];
    const newURL = path.split('/')[1];
    switch (newURL) {
      case 'home':
        this.backgroundcolor = '#f0f4d9';
        break;
      case 'events':
        this.backgroundcolor = '#F3D9B6';
        break;
      case 'survey':
        this.backgroundcolor = '#e5ecf3';
        break;
      default:
        this.backgroundcolor = 'white';
        break;
    }
  }
}
