import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AppServiceService {
  private _apiUrl = environment.apiURL;
  selectedCategoryId: Subject<any>;
  selectedLanguage: BehaviorSubject<any>;
  constructor(
    public http: HttpClient
  ) {
    this.selectedCategoryId = new BehaviorSubject(null);
    this.selectedLanguage = new BehaviorSubject(localStorage.getItem('language') || 'nl');
  }

  login(body) {
    return this.http.post(this._apiUrl + '/user-login', body);
  }

  getAllNews(body) {
    return this.http.post(this._apiUrl + '/messages-list', body);
  }

  getNewsById(body) {
    return this.http.post(this._apiUrl + '/messages-detail', body);
  }

  readNewsById(body) {
    return this.http.post(this._apiUrl + '/messages-read', body);
  }

  getMessageCategory(body) {
    return this.http.post(this._apiUrl + '/messages-category', body);
  }

  getAllUsers(body) {
    return this.http.post(this._apiUrl + '/user-list', body);
  }

  getUserById(body) {
    return this.http.post(this._apiUrl + '/user-detail', body);
  }

  getAllEvents(body) {
    return this.http.post(this._apiUrl + '/event-list', body);
  }

  getEventCategory(body) {
    return this.http.post(this._apiUrl + '/event-category', body);
  }

  getEventDetail(body) {
    return this.http.post(this._apiUrl + '/event-detail', body);
  }

  eventReadById(body) {
    return this.http.post(this._apiUrl + '/event-read', body);
  }

  getDashboardList(body) {
    return this.http.post(this._apiUrl + '/dashboard-list', body);
  }
  checkEmail(body) {
    return this.http.post(this._apiUrl + '/check-email', body);
  }
  createPassword(body) {
    return this.http.post(this._apiUrl + '/create-password', body);
  }

  logout(body) {
    return this.http.post(this._apiUrl + '/logout', body);
  }
}


