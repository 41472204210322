import { Component, OnInit, HostListener } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  userId;
  allNews: any;
  isLoading: boolean = false;
  selectedTab = 1;
  selectedTabIndex = 0;
  language = localStorage.getItem('language');
  private _unsubscribeAll: Subject<any>;
  status: any;
  messageCategory: any;
  categoryId: any;
  pageNo: number = 1;
  noRecordsFound: boolean = false;
  totalPages: number;
  constructor(
    private _appService: AppServiceService,
    private route: ActivatedRoute,
  ) {
    this._unsubscribeAll = new Subject();
    this.route
      .queryParams
      .subscribe(params => {
        if (params.categoryID) {
          this.categoryId = params.categoryID;
          this.getAllNews();
        }
      });
  }

  ngOnInit(): void {
    this.getAllNews();
    this.getMessageCategory()
    // this._appService.selectedLanguage.pipe(takeUntil(this._unsubscribeAll)).subscribe(language => {
    //   this.language = language;
    //   if (localStorage.getItem('language') !== language) {
    //     this.getAllNews();
    //     this.getMessageCategory()
    //   }
    // })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  getAllNews(type?) {
    this.userId = JSON.parse(localStorage.getItem('userData')).id;
    const formData: any = new FormData();
    formData.append('language', this.language);
    formData.append('user_id', this.userId);
    formData.append('page_no', this.pageNo);
    if (this.categoryId)
      formData.append('type', this.categoryId);
    this.isLoading = true;
    this._appService.getAllNews(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((data: any) => {
      if(data) {
        if (data.status === 0) {
          this.noRecordsFound = true;
          this.isLoading = false;
        } else {
          this.totalPages = data.total_count;
          this.isLoading = false;
          this.status = data.status;

          if (type) {
            this.allNews.push(...data.data);
          } else {
            this.allNews = data.data;
          }

          this.allNews = this.allNews.map((data: any) => {
            let iconType = '';
            let backgroundcolor = '';
            let route = ''
            switch(data.type) {
              case 1: // Taking Care
                backgroundcolor = '#BECF59';
                route = 'survey-detail';
                break;
              case 3: // HR News
                backgroundcolor = '#DFE8B1';
                route = 'survey-detail';
                break;
              default:
                break;
            }
            
            return { ...data, iconType, backgroundcolor, route };
          });
          this.isLoading = false;
          this.status = data.status;
          this.totalPages = data.total_count;
        }
      }
    }, error => {
      this.isLoading = false;
    })
  }

  /**
   * 
   * @param event 
   * For Infinite scrolling of message
   */
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (event.target.scrollingElement.scrollTop + event.target.scrollingElement.offsetHeight >= event.target.scrollingElement.scrollHeight && this.pageNo < this.totalPages && !this.isLoading) {
      this.pageNo += 1;
      this.getAllNews('scroll');
    }
  }

  getMessageCategory() {
    if (localStorage.getItem('userData')) {
      this.userId = JSON.parse(localStorage.getItem('userData')).id;
    }
    const formData: any = new FormData();
    formData.append('user_id', this.userId);
    formData.append('language', this.language);
    this._appService.getMessageCategory(formData).pipe(takeUntil(this._unsubscribeAll)).subscribe((messageCategory: any) => {
      if (messageCategory.messageCategory) {
        this.messageCategory = messageCategory.messageCategory;
        // this.messageCategory = messageCategory.messageCategory.map((message: any) => {
        //   const subCategory = message.sub_category.map(cat => ({ ...cat }));
        //   return { ...message, sub_category: subCategory}
        // });
      }
    });
  }

  changeTab(tabIndex: number) {
    this.selectedTabIndex = 1;
    this.selectedTab = tabIndex;
  }
  getColor(subcategory) {
    switch (subcategory) {
      case 'Taking Care':
        return '#BECF59';
      case 'HR news':
        return '#DFE8B1';
      case 'Vaillant group news':
        return 'seagreen';
    }
  }
}
